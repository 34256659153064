// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../configs/apiConfig'


export const evaluateManageCreate = createAsyncThunk('evaluateManage/evaluate/create', async (params) => {
  const response = await apiHandle('POST', 'evaluate/create', params)
  return response
})

export const evaluateManageUpdate = createAsyncThunk('evaluateManage/evaluate/update', async (params) => {
  const response = await apiHandle('PUT', 'evaluate/update', params)
  return response
})

export const evaluateManageRefund = createAsyncThunk('evaluateManage/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const evaluateManageDelete = createAsyncThunk('evaluateManage/evaluate/delete', async (params) => {
  const response = await apiHandle('DELETE', 'evaluate/delete', params)
  return response
})


export const evaluateManagePDFCreate = createAsyncThunk('evaluateManage/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const evaluateManageReaderCard = createAsyncThunk('evaluateManage/evaluateManageReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const evaluateManageGetList = createAsyncThunk('evaluateManage/evaluate/list', async (params) => {
  const response = await apiHandle('GET', 'evaluate/list', params)
  return response
})

export const getDataTable = createAsyncThunk('evaluateManage/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'evaluate/gettable', params)
  return response
})

export const evaluateManageDataDetail = createAsyncThunk('evaluateManage/evaluateManageDataDetail', async (params) => {
  const response = await apiHandle('GET', 'evaluate/get', params)
  return response
})

export const evaluateManageGetCourse = createAsyncThunk('evaluateManage/evaluateManageGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforevaluateManage', params)
  return response
})

export const getAllData = createAsyncThunk('evaluateManage/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('evaluateManage/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('evaluateManage/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('evaluateManage/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('evaluateManage/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultevaluateManageDetail = {
  evaluate_id:null,
  title:null,
  category:null,
  type:null,
  is_activate: null
}


export const evaluateManageSlice = createSlice({
  name: 'evaluateManage',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    evaluateManageData: {
      data: [],
      filter: {
        category: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    evaluateManageDetail: {
      isEdit: false,
      dropdown: {
        category: [],
        status: [],
        type: []
      },
      evaluateManageData: defaultevaluateManageDetail
    },
    alert: {
      active: false,
      message: ''
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'evaluateManage/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    evaluateManageNew: (state, action) => {
      if (action.type === 'evaluateManage/evaluateManageNew') {
        state.evaluateManageDetail.isEdit = false
        state.evaluateManageDetail.evaluateManageData = defaultevaluateManageDetail
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    evaluateManageAddHandle: (state, action) => {
      if (action.type === 'evaluateManage/evaluateManageAddHandle') {
        if (action.payload.target === 'course') {
          state.evaluateManageDetail.evaluateManageData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.evaluateManageDetail.evaluateManageData.other_fee.push(defaultOtherCost)
        }

      }
    },
    evaluateManageDeleteHandle: (state, action) => {
      if (action.type === 'evaluateManage/evaluateManageDeleteHandle') {
        if (action.payload.target === 'course') {
          state.evaluateManageDetail.evaluateManageData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.evaluateManageDetail.evaluateManageData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    evaluateManageDropdownHandle: (state, action) => {
      if (action.type === 'evaluateManage/evaluateManageDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.evaluateManageDetail.isEdit === false) {
          state.evaluateManageDetail.evaluateManageData.course[action.payload.index].course_id = action.payload.value.course_id
          state.evaluateManageDetail.evaluateManageData.course[action.payload.index].course_name = action.payload.value.name
          state.evaluateManageDetail.evaluateManageData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.evaluateManageDetail.evaluateManageData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.evaluateManageDetail.isEdit === false) {
          state.evaluateManageDetail.evaluateManageData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.evaluateManageDetail.evaluateManageData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.evaluateManageDetail.evaluateManageData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.evaluateManageDetail.evaluateManageData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    evaluateManageOnChangeHandle: (state, action) => {
      if (action.type === 'evaluateManage/evaluateManageOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.evaluateManageDetail.evaluateManageData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.evaluateManageDetail.evaluateManageData[action.payload.target] = action.payload.value
        }

      }
    },
    evaluateManageLoading: (state, action) => {
      if (action.type === 'evaluateManage/evaluateManageLoading') {
        state.evaluateManageData.isLoading = true
      }
    },
    evaluateManageAlert: (state, action) => {
      if (action.type === 'evaluateManage/evaluateManageAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    evaluateManageRefundDelete: (state, action) => {
      if (action.type === 'evaluateManage/evaluateManageRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(evaluateManageCreate.fulfilled, (state, action) => {
        if (action.type === 'evaluateManage/evaluate/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.evaluateManageDetail.evaluateManageData.createStatus = true
            // state.evaluateManageDetail.evaluateManageData.pdf = action.payload.data
          }
        }
      })
      .addCase(evaluateManageUpdate.fulfilled, (state, action) => {
        if (action.type === 'evaluateManage/evaluate/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.evaluateManageDetail.evaluateManageData.createStatus = true
            // state.evaluateManageDetail.evaluateManageData.pdf = action.payload.data
          }
        }
      })
      .addCase(evaluateManageRefund.fulfilled, (state, action) => {
        if (action.type === 'evaluateManage/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(evaluateManageDelete.fulfilled, (state, action) => {
        if (action.type === 'evaluateManage/evaluate/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(evaluateManageGetList.fulfilled, (state, action) => {
        if (action.type === 'evaluateManage/evaluate/list/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            let category = []
            let status = []
            let type = []
           

            if (action.payload.data && Array.isArray(action.payload.data.category)) {
              for (const i of action.payload.data.category) {
                category.push({ value: i?.value, label: i?.name })
              }
            }
            state.evaluateManageDetail.dropdown.category = category

            if (action.payload.data && Array.isArray(action.payload.data.status)) {
              for (const i of action.payload.data.status) {
                status.push({ value:  i?.value, label: i?.name })
              }
            }
            state.evaluateManageDetail.dropdown.status = status

            if (action.payload.data && Array.isArray(action.payload.data.type)) {
              for (const i of action.payload.data.type) {
                type.push({ value:  i?.value, label: i?.name })
              }
            }
            state.evaluateManageDetail.dropdown.type = type
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'evaluateManage/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.evaluateManageData = action.payload
            state.evaluateManageData.isLoading = false
          }
        }
      })
      .addCase(evaluateManageDataDetail.fulfilled, (state, action) => {
        if (action.type === 'evaluateManage/evaluateManageDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.evaluateManageDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              if (data === null) data = ''
              state.evaluateManageDetail.evaluateManageData[i] = data
            }
          }
        }
      })
      .addCase(evaluateManageGetCourse.fulfilled, (state, action) => {
        if (action.type === 'evaluateManage/evaluateManageGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.evaluateManageDetail.dropdown.course = action.payload.course
            state.evaluateManageDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(evaluateManageReaderCard.fulfilled, (state, action) => {
        if (action.type === 'evaluateManage/evaluateManageReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.evaluateManageDetail.evaluateManageData.prefix = action.payload.title_th
            state.evaluateManageDetail.evaluateManageData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.evaluateManageDetail.evaluateManageData.idcard = action.payload.nat_id
            state.evaluateManageDetail.evaluateManageData.birthday = action.payload.birthdate
            state.evaluateManageDetail.evaluateManageData.address = action.payload.address_no
            state.evaluateManageDetail.evaluateManageData.street = action.payload.address_road
            state.evaluateManageDetail.evaluateManageData.province = action.payload.address_provinice
            state.evaluateManageDetail.evaluateManageData.district = action.payload.address_amphor
            state.evaluateManageDetail.evaluateManageData.subdistrict = action.payload.address_tumbol
            state.evaluateManageDetail.evaluateManageData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(evaluateManagePDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'evaluateManage/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, evaluateManageNew, evaluateManageAddHandle, evaluateManageDropdownHandle, evaluateManageDeleteHandle, evaluateManageOnChangeHandle, evaluateManageLoading, evaluateManageAlert, evaluateManageRefundDelete } = evaluateManageSlice.actions

export default evaluateManageSlice.reducer
