// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'

import apiHandle from '../../../../../configs/apiConfig'

export const incomeCreate = createAsyncThunk('income/create', async (params) => {
  const response = await apiHandle('POST', 'income/create', params)
  return response
})

export const incomeUpdate = createAsyncThunk('income/update', async (params) => {
  const response = await apiHandle('PUT', 'income/update', params)
  return response
})

export const getDataTable = createAsyncThunk('income/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'accounting/incometable', params)
  return response
})
export const incomeReaderCard = createAsyncThunk('income/incomeReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})
export const incomeCheckExsit = createAsyncThunk('income/incomeCheckExsit', async (params) => {
  const response = await apiHandle('GET', 'customer/check', params)
  return response
})

export const getDataList = createAsyncThunk('expenses/list', async (params) => {
  const response = await apiHandle('GET', 'expenses/list', params)
  return response
})

export const incomeDataDetail = createAsyncThunk('income/incomeDataDetail', async (params) => {
  const response = await apiHandle('GET', 'income/get', params)
  return response
})

export const incomeGetCourse = createAsyncThunk('income/incomeGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforregister', params)
  return response
})

export const incomePDFPaymentCreate = createAsyncThunk('income/applicationfee/pdfpayment/create', async (params) => {
  const response = await apiHandle('POST', 'applicationfee/pdfpayment', params)
  return response
})

export const incomePDFCreate = createAsyncThunk('income/applicationfee/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'applicationfee/pdf', params)
  return response
})

export const incomeDelete = createAsyncThunk('income/delete', async (params) => {
  const response = await apiHandle('DELETE', 'income/delete', params)
  return response
})


const defaultIncomeDetail = {
  list_expenses: null,
  date: new Date(),
  vehicle: null,
  employee: null,
  detail: null,
  pay_type: null,
  mile: null,
  value: null,
  file: null,
  file_type: null,
  application_id: null,
  application_other_fee_id: null,
  createdAt: null,
  expenses_id: null,
  name: null,
  other_name: null,
  pay_type: null,
  type: null,
  user: null,
  other_income: null,
  paid: null,
  payment_id: null
}


export const incomeSlice = createSlice({
  name: 'income',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedData: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: '',
      startDate: '',
      endDate: ''
    },
    incomeData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    incomeDetail: {
      isEdit: false,
      dropdown: {
        lists: [],
        employees: [],
        vehicles: [],
        payType: [
          { label: 'เงินสด', value: 'เงินสด' },
          { value: 'โอน', label: 'โอน' }
        ]
      },
      detail: defaultIncomeDetail
    },
    alert: {
      active: false,
      message: '',
      err: false
    },
    chart: {
      expenses_donut: [],
      income_column: [],
      income_donut: [],
      total_expenses: null,
      total_income: null
    },
    submitLoading: false
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'income/searchCriteria') {
        console.log(action)
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    incomeNew: (state, action) => {
      if (action.type === 'income/incomeNew') {
        state.incomeDetail.isEdit = false
        state.incomeDetail.detail = defaultIncomeDetail
      }
    },
    incomeAddHandle: (state, action) => {
      console.log(action)
      if (action.type === 'income/incomeAddHandle') {
        if (action.payload.target === 'course') {
          state.incomeDetail.detail.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'income') {
          state.incomeDetail.detail.income_fee.push(defaultIncomeCost)
        }

      }
    },
    incomeDeleteHandle: (state, action) => {
      if (action.type === 'income/incomeDeleteHandle') {
        if (action.payload.target === 'course') {
          state.incomeDetail.detail.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'income') {
          state.incomeDetail.detail.income_fee.splice(action.payload.index, 1)
        }

      }

    },
    incomeDropdownHandle: (state, action) => {
      if (action.type === 'income/incomeDropdownHandle') {
        if (action.payload.target === 'course' && state.incomeDetail.isEdit === false) {
          state.incomeDetail.detail.course[action.payload.index].course_id = action.payload.value.course_id
          state.incomeDetail.detail.course[action.payload.index].course_name = action.payload.value.name
          state.incomeDetail.detail.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.incomeDetail.detail.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'income' && state.incomeDetail.isEdit === false) {
          state.incomeDetail.detail.income_fee[action.payload.index].income_fee_id = action.payload.value.income_fee_id
          state.incomeDetail.detail.income_fee[action.payload.index].income_fee_name = action.payload.value.name
          state.incomeDetail.detail.income_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.incomeDetail.detail[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    incomeOnChangeHandle: (state, action) => {
      if (action.type === 'income/incomeOnChangeHandle') {
        state.incomeDetail.detail[action.payload.target] = action.payload.value

      }
    },
    incomeLoading: (state, action) => {
      if (action.type === 'income/incomeLoading') {
        state.incomeData.isLoading = true
      }
    },
    incomeAlert: (state, action) => {
      if (action.type === 'income/incomeAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    incomeSubmitLoading: (state, action) => {
      if (action.type === 'income/incomeSubmitLoading') {
        state.submitLoading = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(incomeCreate.fulfilled, (state, action) => {
        if (action.type === 'income/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            state.submitLoading = false
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            state.submitLoading = false
          }
        }
      })
      .addCase(incomeUpdate.fulfilled, (state, action) => {
        if (action.type === 'income/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            state.submitLoading = false
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            state.submitLoading = false
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'income/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.incomeData = action.payload
            state.chart = action.payload.chart
            state.incomeData.isLoading = false
          }
        }
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        if (action.type === 'expenses/list/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.name, value: i.list_expenses_id })
            }
            state.incomeDetail.dropdown.lists = arr
          }
        }
      })
      .addCase(incomeReaderCard.fulfilled, (state, action) => {
        if (action.type === 'income/incomeReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.incomeDetail.detail.prefix = action.payload.title_th
            state.incomeDetail.detail.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.incomeDetail.detail.idcard = action.payload.nat_id
            state.incomeDetail.detail.birthday = action.payload.birthdate
            state.incomeDetail.detail.address = action.payload.address_no
            state.incomeDetail.detail.street = action.payload.address_road
            state.incomeDetail.detail.province = action.payload.address_provinice
            state.incomeDetail.detail.district = action.payload.address_amphor
            state.incomeDetail.detail.subdistrict = action.payload.address_tumbol
            state.incomeDetail.detail.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(incomeDataDetail.fulfilled, (state, action) => {
        if (action.type === 'income/incomeDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.incomeDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              if (i === 'pay_type' && data) data = { label: data, value: data }
              state.incomeDetail.detail[i] = data
            }
          }
        }
      })
      .addCase(incomeCheckExsit.fulfilled, (state, action) => {
        if (action.type === 'income/incomeCheckExsit/fulfilled') {
          console.log(action)
          if (action.payload.status === 'success') {
            if (action.payload.data) {
              state.incomeDetail.detail.prefix = action.payload.data.prefix ? action.payload.data.prefix : ''
              state.incomeDetail.detail.name = action.payload.data.name ? action.payload.data.name : ''
              state.incomeDetail.detail.idcard = action.payload.data.idcard ? action.payload.data.idcard : ''
              state.incomeDetail.detail.birthday = action.payload.data.birthday ? action.payload.data.birthday : null
              state.incomeDetail.detail.address = action.payload.data.address ? action.payload.data.address : ''
              state.incomeDetail.detail.moo = action.payload.data.moo ? action.payload.data.moo : ''
              state.incomeDetail.detail.soi = action.payload.data.soi ? action.payload.data.soi : ''
              state.incomeDetail.detail.street = action.payload.data.street ? action.payload.data.street : ''
              state.incomeDetail.detail.province = action.payload.data.province ? action.payload.data.province : ''
              state.incomeDetail.detail.district = action.payload.data.district ? action.payload.data.district : ''
              state.incomeDetail.detail.subdistrict = action.payload.data.subdistrict ? action.payload.data.subdistrict : ''
              state.incomeDetail.detail.phone_1 = action.payload.data.phone_1 ? action.payload.data.phone_1 : ''
              state.incomeDetail.detail.phone_2 = action.payload.data.phone_2 ? action.payload.data.phone_2 : ''
            } else {

            }

          } else {
            //handleError
          }
        }
      })
      .addCase(incomeGetCourse.fulfilled, (state, action) => {
        if (action.type === 'income/incomeGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.incomeDetail.dropdown.course = action.payload.course
            state.incomeDetail.dropdown.income_fee = action.payload.income_fee
          }
        }
      })
      .addCase(incomePDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'income/applicationfee/pdf/create/fulfilled') {
          console.log(action)
          if (action.payload.status === 'success') {
            console.log(action)
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(incomePDFPaymentCreate.fulfilled, (state, action) => {
        if (action.type === 'income/applicationfee/pdfpayment/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(incomeDelete.fulfilled, (state, action) => {
        if (action.type === 'income/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
  }
})

export const { searchCriteria, incomeNew, incomeAddHandle, incomeDropdownHandle, incomeDeleteHandle, incomeOnChangeHandle, incomeLoading, incomeAlert, incomeSubmitLoading } = incomeSlice.actions

export default incomeSlice.reducer
