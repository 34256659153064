// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'

import apiHandle from '../../../../../configs/apiConfig'

export const expensesCreate = createAsyncThunk('expenses/create', async (params) => {
  const response = await apiHandle('POST', 'expenses/create', params)
  return response
})

export const expensesUpdate = createAsyncThunk('expenses/update', async (params) => {
  const response = await apiHandle('PUT', 'expenses/update', params)
  return response
})

export const getDataTable = createAsyncThunk('expenses/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'accounting/expensestable', params)
  return response
})
export const getDataList = createAsyncThunk('expenses/list', async (params) => {
  const response = await apiHandle('GET', 'expenses/list', params)
  return response
})

export const getDataListCategory = createAsyncThunk('expenses/list-category', async (params) => {
  const response = await apiHandle('GET', 'expenses/list-category', params)
  return response
})

export const getDataEmployee = createAsyncThunk('expenses/employee', async (params) => {
  const response = await apiHandle('GET', 'expenses/employee', params)
  return response
})
export const getDataVehicle = createAsyncThunk('expenses/vehicle', async (params) => {
  const response = await apiHandle('GET', 'expenses/vehicle', params)
  return response
})

export const getDataVehicleAll = createAsyncThunk('expenses/vehicle-all', async (params) => {
  const response = await apiHandle('GET', 'expenses/vehicle-all', params)
  return response
})

export const expensesReaderCard = createAsyncThunk('expenses/expensesReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})
export const expensesCheckExsit = createAsyncThunk('expenses/expensesCheckExsit', async (params) => {
  const response = await apiHandle('GET', 'customer/check', params)
  return response
})

export const expensesDataDetail = createAsyncThunk('expenses/expensesDataDetail', async (params) => {
  const response = await apiHandle('GET', 'expenses/get', params)
  return response
})

export const expensesGetCourse = createAsyncThunk('expenses/expensesGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforregister', params)
  return response
})

export const expensesPDFPaymentCreate = createAsyncThunk('expenses/applicationfee/pdfpayment/create', async (params) => {
  const response = await apiHandle('POST', 'applicationfee/pdfpayment', params)
  return response
})

export const expensesPDFCreate = createAsyncThunk('expenses/applicationfee/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'applicationfee/pdf', params)
  return response
})

export const expensesDelete = createAsyncThunk('expenses/delete', async (params) => {
  const response = await apiHandle('DELETE', 'expenses/delete', params)
  return response
})


const defaultExpensesDetail = {
  category: null,
  list_expenses: null,
  date: new Date(),
  vehicle: null,
  employee: null,
  detail: null,
  pay_type: null,
  mile: null,
  value: null,
  file: null,
  file_type: null,
  application_id: null,
  application_other_fee_id: null,
  createdAt: null,
  expenses_id: null,
  name: null,
  other_name: null,
  pay_type: null,
  type: null,
  user: null,
  payment_id: null
}


export const expensesSlice = createSlice({
  name: 'expenses',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedData: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: '',
      startDate: '',
      endDate: ''
    },
    expensesData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    expensesDetail: {
      isEdit: false,
      dropdown: {
        lists: [],
        listsCategory: [],
        employees: [],
        vehicles: [],
        payType: [
          { label: 'เงินสด', value: 'เงินสด' },
          { value: 'โอน', label: 'โอน' }
        ]
      },
      detail: defaultExpensesDetail
    },
    alert: {
      active: false,
      message: '',
      err: false
    },
    chart: {
      expenses_donut: [],
      income_column: [],
      income_donut: [],
      total_expenses: null,
      total_income: null
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'expenses/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    expensesNew: (state, action) => {
      if (action.type === 'expenses/expensesNew') {
        state.expensesDetail.isEdit = false
        state.expensesDetail.detail = defaultExpensesDetail
      }
    },
    expensesAlert: (state, action) => {
      if (action.type === 'expenses/expensesAlert') {
        state.alert.active = false
        state.alert.message = ''
      }
    },
    // expensesDeleteHandle: (state, action) => {
    //   if (action.type === 'expenses/expensesDeleteHandle') {
    //     if (action.payload.target === 'course') {
    //       state.expensesDetail.userData.course.splice(action.payload.index, 1)
    //     }
    //     if (action.payload.target === 'expenses') {
    //       state.expensesDetail.userData.expenses_fee.splice(action.payload.index, 1)
    //     }

    //   }

    // },
    expensesDropdownHandle: (state, action) => {
      if (action.type === 'expenses/expensesDropdownHandle') {
        state.expensesDetail.detail[action.payload.target] = action.payload.value
      }
    },
    expensesOnChangeHandle: (state, action) => {
      if (action.type === 'expenses/expensesOnChangeHandle') {
        state.expensesDetail.detail[action.payload.target] = action.payload.value
      }
    },
    expensesLoading: (state, action) => {
      if (action.type === 'expenses/expensesLoading') {
        state.expensesData.isLoading = true
      }
    },
    expensesAlert: (state, action) => {
      if (action.type === 'expenses/expensesAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    expensesSubmitLoading: (state, action) => {
      if (action.type === 'expenses/expensesSubmitLoading') {
        state.submitLoading = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(expensesCreate.fulfilled, (state, action) => {
        if (action.type === 'expenses/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            state.submitLoading = false
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            state.submitLoading = false
          }
        }
      })
      .addCase(expensesUpdate.fulfilled, (state, action) => {
        if (action.type === 'expenses/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            state.submitLoading = false
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            state.submitLoading = false
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'expenses/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.expensesData = action.payload
            state.chart = action.payload.chart
            state.expensesData.isLoading = false
          }
        }
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        if (action.type === 'expenses/list/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.name, value: i.list_expenses_id })
            }
            state.expensesDetail.dropdown.lists = arr
          }
        }
      })
      .addCase(getDataListCategory.fulfilled, (state, action) => {
        if (action.type === 'expenses/list-category/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.category, value: i.category })
            }
            for (const i of arr) {
              const arr_list = []
              for (let j of i?.list_expenses) {
                arr_list.push({ ...j, label: j.name, value: j.list_expenses_id })
                i.list_expenses = arr_list
              }
            }
            state.expensesDetail.dropdown.listsCategory = arr
          }
        }
      })
      .addCase(getDataEmployee.fulfilled, (state, action) => {
        if (action.type === 'expenses/employee/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.name, value: i.value })
            }
            state.expensesDetail.dropdown.employees = arr
          }
        }
      })
      .addCase(getDataVehicle.fulfilled, (state, action) => {
        if (action.type === 'expenses/vehicle/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.name, value: i.value })
            }
            state.expensesDetail.dropdown.vehicles = arr
          }
        }
      })
      .addCase(getDataVehicleAll.fulfilled, (state, action) => {
        if (action.type === 'expenses/vehicle-all/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.name, value: i.value })
            }
            state.expensesDetail.dropdown.vehicles = arr
          }
        }
      })
      .addCase(expensesReaderCard.fulfilled, (state, action) => {
        if (action.type === 'expenses/expensesReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.expensesDetail.userData.prefix = action.payload.title_th
            state.expensesDetail.userData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.expensesDetail.userData.idcard = action.payload.nat_id
            state.expensesDetail.userData.birthday = action.payload.birthdate
            state.expensesDetail.userData.address = action.payload.address_no
            state.expensesDetail.userData.street = action.payload.address_road
            state.expensesDetail.userData.province = action.payload.address_provinice
            state.expensesDetail.userData.district = action.payload.address_amphor
            state.expensesDetail.userData.subdistrict = action.payload.address_tumbol
            state.expensesDetail.userData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(expensesDataDetail.fulfilled, (state, action) => {
        if (action.type === 'expenses/expensesDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.expensesDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              // if (data === null) data = ''
              if (i === 'pay_type' && data) data = { label: data, value: data }
              if (i === 'list_expense' && data) {
                if (state.expensesDetail.dropdown.lists.length > 0) {
                  let list_expenses = JSON.parse(JSON.stringify(state.expensesDetail.dropdown.lists)).find(item => item.list_expenses_id === data.list_expenses_id)
                  if (list_expenses) {
                    state.expensesDetail.detail.category = { label: data?.category, value: data?.category }
                    state.expensesDetail.detail.list_expenses = { ...list_expenses, label: list_expenses.name, value: list_expenses.list_expenses_id }
                  } else {
                    state.expensesDetail.detail.list_expenses = null
                  }

                } else {
                  state.expensesDetail.detail.list_expenses = null
                }
              }
              state.expensesDetail.detail[i] = data
            }
          }
        }
      })
      .addCase(expensesCheckExsit.fulfilled, (state, action) => {
        if (action.type === 'expenses/expensesCheckExsit/fulfilled') {
          console.log(action)
          if (action.payload.status === 'success') {
            if (action.payload.data) {
              state.expensesDetail.userData.prefix = action.payload.data.prefix ? action.payload.data.prefix : ''
              state.expensesDetail.userData.name = action.payload.data.name ? action.payload.data.name : ''
              state.expensesDetail.userData.idcard = action.payload.data.idcard ? action.payload.data.idcard : ''
              state.expensesDetail.userData.birthday = action.payload.data.birthday ? action.payload.data.birthday : null
              state.expensesDetail.userData.address = action.payload.data.address ? action.payload.data.address : ''
              state.expensesDetail.userData.moo = action.payload.data.moo ? action.payload.data.moo : ''
              state.expensesDetail.userData.soi = action.payload.data.soi ? action.payload.data.soi : ''
              state.expensesDetail.userData.street = action.payload.data.street ? action.payload.data.street : ''
              state.expensesDetail.userData.province = action.payload.data.province ? action.payload.data.province : ''
              state.expensesDetail.userData.district = action.payload.data.district ? action.payload.data.district : ''
              state.expensesDetail.userData.subdistrict = action.payload.data.subdistrict ? action.payload.data.subdistrict : ''
              state.expensesDetail.userData.phone_1 = action.payload.data.phone_1 ? action.payload.data.phone_1 : ''
              state.expensesDetail.userData.phone_2 = action.payload.data.phone_2 ? action.payload.data.phone_2 : ''
            } else {

            }

          } else {
            //handleError
          }
        }
      })
      .addCase(expensesGetCourse.fulfilled, (state, action) => {
        if (action.type === 'expenses/expensesGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.expensesDetail.dropdown.course = action.payload.course
            state.expensesDetail.dropdown.expenses_fee = action.payload.expenses_fee
          }
        }
      })
      .addCase(expensesPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'expenses/applicationfee/pdf/create/fulfilled') {
          console.log(action)
          if (action.payload.status === 'success') {
            console.log(action)
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(expensesPDFPaymentCreate.fulfilled, (state, action) => {
        if (action.type === 'expenses/applicationfee/pdfpayment/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(expensesDelete.fulfilled, (state, action) => {
        if (action.type === 'expenses/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
  }
})

export const { searchCriteria, expensesNew, expensesAddHandle, expensesDropdownHandle, expensesDeleteHandle, expensesOnChangeHandle, expensesLoading, expensesAlert, expensesSubmitLoading } = expensesSlice.actions

export default expensesSlice.reducer
