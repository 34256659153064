// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../configs/apiConfig'


export const manageEmployeeCreate = createAsyncThunk('manageEmployee/user/register', async (params) => {
  const response = await apiHandle('POST', 'user/register', params)
  return response
})

export const manageEmployeeUpdate = createAsyncThunk('manageEmployee/user/update', async (params) => {
  const response = await apiHandle('PUT', 'user/update', params)
  return response
})

export const manageEmployeeRefund = createAsyncThunk('manageEmployee/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const manageEmployeeDelete = createAsyncThunk('manageEmployee/user/delete', async (params) => {
  const response = await apiHandle('DELETE', 'user/delete', params)
  return response
})


export const manageEmployeePDFCreate = createAsyncThunk('manageEmployee/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const manageEmployeeReaderCard = createAsyncThunk('manageEmployee/manageEmployeeReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const manageEmployeeGetList = createAsyncThunk('manageEmployee/user/listforregister', async (params) => {
  const response = await apiHandle('GET', 'user/listforregister', params)
  return response
})

export const manageEmployeeGetBranch = createAsyncThunk('manageEmployee/branch/get', async (params) => {
  const response = await apiHandle('GET', 'branch/get', params)
  return response
})

export const getDataTable = createAsyncThunk('manageEmployee/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'user/gettable', params)
  return response
})

export const manageEmployeeDataDetail = createAsyncThunk('manageEmployee/manageEmployeeDataDetail', async (params) => {
  const response = await apiHandle('GET', 'user/get', params)
  return response
})

export const manageEmployeeGetCourse = createAsyncThunk('manageEmployee/manageEmployeeGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getformanageEmployee', params)
  return response
})

export const getAllData = createAsyncThunk('manageEmployee/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('manageEmployee/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('manageEmployee/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('manageEmployee/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('manageEmployee/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultEmployeeDetail = {
  user_id: '',
  username: '',
  password: '',
  role_id: '',
  branch_id: '',
  idcard: '',
  prefix: '',
  fname: '',
  sname: '',
  prefix_en: '',
  fname_en: '',
  sname_en: '',
  nickname: '',
  birthday: '',
  address: '',
  moo: '',
  soi: '',
  street: '',
  subdistrict: '',
  district: '',
  province: '',
  phone_1: '',
  phone_2: '',
  email: '',
  start_date: '',
  department: '',
  position: '',
  bank: '',
  bank_acc: '',
  branch_branch: '',
  emergency_name: '',
  emergency_phone: '',
  emergency_relation: '',
  photo: '', //base 64
  employee_type: '',
  salary: '',
  extra_salary: '',
  salary_pay_type: '',
  sso_salary:'',
  day_off: [],
  is_salary_daily:'',
  createStatus: false
}


export const manageEmployeeSlice = createSlice({
  name: 'manageEmployee',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ค้างชำระ', value: 'ค้างชำระ' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    manageEmployeeData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    manageEmployeeDetail: {
      isEdit: false,
      dropdown: {
        bank: [],
        department: [],
        position: [],
        role: [],
        days: [
          { label: 'วันจันทร์', value: 'วันจันทร์' },
          { label: 'วันอังคาร', value: 'วันอังคาร' },
          { label: 'วันพุธ', value: 'วันพุธ' },
          { label: 'วันพฤหัสบดี', value: 'วันพฤหัสบดี' },
          { label: 'วันศุกร์', value: 'วันศุกร์' },
          { label: 'วันเสาร์', value: 'วันเสาร์' },
          { label: 'วันอาทิตย์', value: 'วันอาทิตย์' }
        ],
        pay_type: [
          { label: 'รายวัน', value: 'รายวัน' },
          { label: 'รายเดือน', value: 'รายเดือน' }
        ],
        salary_pay_type: [
          { label: 'เงินสด', value: 'เงินสด' },
          { label: 'โอน', value: 'โอน' }
        ],
        bank_account_type: [{ label: 'บัญชีออมทรัพย์', value: 'บัญชีออมทรัพย์' }],
        branch: []
      },
      employeeData: defaultEmployeeDetail
    },
    alert: {
      active: false,
      message: '',
      err: false
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'manageEmployee/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    manageEmployeeNew: (state, action) => {
      if (action.type === 'manageEmployee/manageEmployeeNew') {
        state.manageEmployeeDetail.isEdit = false
        state.manageEmployeeDetail.employeeData = defaultEmployeeDetail
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageEmployeeAddHandle: (state, action) => {
      if (action.type === 'manageEmployee/manageEmployeeAddHandle') {
        if (action.payload.target === 'course') {
          state.manageEmployeeDetail.userData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.manageEmployeeDetail.userData.other_fee.push(defaultOtherCost)
        }

      }
    },
    manageEmployeeDeleteHandle: (state, action) => {
      if (action.type === 'manageEmployee/manageEmployeeDeleteHandle') {
        if (action.payload.target === 'course') {
          state.manageEmployeeDetail.userData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.manageEmployeeDetail.userData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    manageEmployeeDropdownHandle: (state, action) => {
      if (action.type === 'manageEmployee/manageEmployeeDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.manageEmployeeDetail.isEdit === false) {
          state.manageEmployeeDetail.userData.course[action.payload.index].course_id = action.payload.value.course_id
          state.manageEmployeeDetail.userData.course[action.payload.index].course_name = action.payload.value.name
          state.manageEmployeeDetail.userData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.manageEmployeeDetail.userData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.manageEmployeeDetail.isEdit === false) {
          state.manageEmployeeDetail.userData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.manageEmployeeDetail.userData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.manageEmployeeDetail.userData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.manageEmployeeDetail.userData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    manageEmployeeOnChangeHandle: (state, action) => {
      if (action.type === 'manageEmployee/manageEmployeeOnChangeHandle') {
        state.manageEmployeeDetail.employeeData[action.payload.target] = action.payload.value
      }
    },
    manageEmployeeLoading: (state, action) => {
      if (action.type === 'manageEmployee/manageEmployeeLoading') {
        state.manageEmployeeData.isLoading = true
      }
    },
    manageEmployeeAlert: (state, action) => {
      if (action.type === 'manageEmployee/manageEmployeeAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageEmployeeRefundDelete: (state, action) => {
      if (action.type === 'manageEmployee/manageEmployeeRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(manageEmployeeCreate.fulfilled, (state, action) => {
        if (action.type === 'manageEmployee/user/register/fulfilled') {
          if (action.payload.status === 'error') {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            //handleError
          } else {
            console.log(action)
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.manageEmployeeDetail.userData.createStatus = true
            // state.manageEmployeeDetail.userData.pdf = action.payload.data
          }
        }
      })
      .addCase(manageEmployeeUpdate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'manageEmployee/user/update/fulfilled') {
          if (action.payload.status === 'error') {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            //handleError
          } else {
            console.log(action)
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.manageEmployeeDetail.userData.createStatus = true
            // state.manageEmployeeDetail.userData.pdf = action.payload.data
          }
        }
      })
      .addCase(manageEmployeeRefund.fulfilled, (state, action) => {
        if (action.type === 'manageEmployee/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(manageEmployeeDelete.fulfilled, (state, action) => {
       
        if (action.type === 'manageEmployee/user/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      // .addCase(manageEmployeeGetBranch.fulfilled, (state, action) => {
      //   if (action.type === 'manageEmployee/branch/get/fulfilled') {
      //     if (action.payload.status === 'error') {
      //       //handleError
      //     } else {
      //       let arr = []
      //       if (Array.isArray(action.payload.data)) {
      //         for (const i of action.payload.data) {
      //           arr.push({ ...i, value: i.branch_id, label: i.name })
      //         }
      //       }
      //       state.manageEmployeeDetail.dropdown.branch = arr
      //     }
      //   }
      // })
      .addCase(manageEmployeeGetList.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'manageEmployee/user/listforregister/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            if (action.payload.data.role) {
              let role = []
              for (const i of action.payload.data.role) {
                role.push({ ...i, value: i.role_id, label: i.name })
              }
              state.manageEmployeeDetail.dropdown.role = role
            } if (action.payload.data.bank) {
              let bank = []
              for (const i of action.payload.data.bank) {
                bank.push({ value: i, label: i })
              }
              state.manageEmployeeDetail.dropdown.bank = bank
            } if (action.payload.data.department) {
              let department = []
              for (const i of action.payload.data.department) {
                department.push({ value: i, label: i })
              }
              state.manageEmployeeDetail.dropdown.department = department
            } if (action.payload.data.position) {
              let position = []
              for (const i of action.payload.data.position) {
                position.push({ value: i, label: i })
              }
              state.manageEmployeeDetail.dropdown.position = position
            } if (action.payload.data.branch) {
              let branch = []
              for (const i of action.payload.data.branch) {
                branch.push({ value: i.branch_id, label: i.name })
              }
              state.manageEmployeeDetail.dropdown.branch = branch
            }
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'manageEmployee/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageEmployeeData = action.payload
            state.manageEmployeeData.isLoading = false
          }
        }
      })
      .addCase(manageEmployeeDataDetail.fulfilled, (state, action) => {
        if (action.type === 'manageEmployee/manageEmployeeDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageEmployeeDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              if (data === null) data = ''
              state.manageEmployeeDetail.employeeData[i] = data
            }
          }
        }
      })
      .addCase(manageEmployeeGetCourse.fulfilled, (state, action) => {
        if (action.type === 'manageEmployee/manageEmployeeGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageEmployeeDetail.dropdown.course = action.payload.course
            state.manageEmployeeDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(manageEmployeeReaderCard.fulfilled, (state, action) => {
        if (action.type === 'manageEmployee/manageEmployeeReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.manageEmployeeDetail.userData.prefix = action.payload.title_th
            state.manageEmployeeDetail.userData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.manageEmployeeDetail.userData.idcard = action.payload.nat_id
            state.manageEmployeeDetail.userData.birthday = action.payload.birthdate
            state.manageEmployeeDetail.userData.address = action.payload.address_no
            state.manageEmployeeDetail.userData.street = action.payload.address_road
            state.manageEmployeeDetail.userData.province = action.payload.address_provinice
            state.manageEmployeeDetail.userData.district = action.payload.address_amphor
            state.manageEmployeeDetail.userData.subdistrict = action.payload.address_tumbol
            state.manageEmployeeDetail.userData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(manageEmployeePDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'manageEmployee/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, manageEmployeeNew, manageEmployeeAddHandle, manageEmployeeDropdownHandle, manageEmployeeDeleteHandle, manageEmployeeOnChangeHandle, manageEmployeeLoading, manageEmployeeAlert, manageEmployeeRefundDelete } = manageEmployeeSlice.actions

export default manageEmployeeSlice.reducer
