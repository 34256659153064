// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'

import apiHandle from '../../../../configs/apiConfig'

export const AccountCreate = createAsyncThunk('Account/create', async (params) => {
  const response = await apiHandle('POST', 'Account/create', params)
  return response
})

export const AccountUpdate = createAsyncThunk('Account/update', async (params) => {
  const response = await apiHandle('PUT', 'Account/update', params)
  return response
})

export const getDataTable = createAsyncThunk('Account/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'accounting/Accounttable', params)
  return response
})
export const getDataList = createAsyncThunk('Account/list', async (params) => {
  const response = await apiHandle('GET', 'Account/list', params)
  return response
})

export const getDataListCategory = createAsyncThunk('Account/list-category', async (params) => {
  const response = await apiHandle('GET', 'Account/list-category', params)
  return response
})

export const getDataEmployee = createAsyncThunk('Account/employee', async (params) => {
  const response = await apiHandle('GET', 'Account/employee', params)
  return response
})
export const getDataVehicle = createAsyncThunk('Account/vehicle', async (params) => {
  const response = await apiHandle('GET', 'Account/vehicle', params)
  return response
})

export const getDataVehicleAll = createAsyncThunk('Account/vehicle-all', async (params) => {
  const response = await apiHandle('GET', 'Account/vehicle-all', params)
  return response
})

export const AccountReaderCard = createAsyncThunk('Account/AccountReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})
export const AccountCheckExsit = createAsyncThunk('Account/AccountCheckExsit', async (params) => {
  const response = await apiHandle('GET', 'customer/check', params)
  return response
})

export const AccountDataDetail = createAsyncThunk('Account/AccountDataDetail', async (params) => {
  const response = await apiHandle('GET', 'Account/get', params)
  return response
})

export const AccountGetCourse = createAsyncThunk('Account/AccountGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforregister', params)
  return response
})

export const AccountPDFPaymentCreate = createAsyncThunk('Account/applicationfee/pdfpayment/create', async (params) => {
  const response = await apiHandle('POST', 'applicationfee/pdfpayment', params)
  return response
})

export const AccountPDFCreate = createAsyncThunk('Account/accounting/printpdf', async (params) => {
  const response = await apiHandle('POST', 'accounting/printpdf', params)
  return response
})


const defaultAccountDetail = {
  category: null,
  list_Account: null,
  date: new Date(),
  vehicle: null,
  employee: null,
  detail: null,
  pay_type: null,
  mile: null,
  value: null,
  file: null,
  file_type: null,
  application_id: null,
  application_other_fee_id: null,
  createdAt: null,
  Account_id: null,
  name: null,
  other_name: null,
  pay_type: null,
  type: null,
  user: null,
  payment_id: null
}


export const AccountSlice = createSlice({
  name: 'Account',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedData: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: '',
      startDate: new Date(),
      endDate: new Date()
    },
    AccountData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    AccountDetail: {
      isEdit: false,
      dropdown: {
        lists: [],
        listsCategory: [],
        employees: [],
        vehicles: [],
        payType: [
          { label: 'เงินสด', value: 'เงินสด' },
          { value: 'โอน', label: 'โอน' }
        ]
      },
      detail: defaultAccountDetail
    },
    alert: {
      active: false,
      message: '',
      err: false
    },
    chart: {
      Account_donut: [],
      income_column: [],
      income_donut: [],
      total_Account: null,
      total_income: null
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'Account/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    AccountNew: (state, action) => {
      if (action.type === 'Account/AccountNew') {
        state.AccountDetail.isEdit = false
        state.AccountDetail.detail = defaultAccountDetail
      }
    },
    AccountAlert: (state, action) => {
      if (action.type === 'Account/AccountAlert') {
        state.alert.active = false
        state.alert.message = ''
      }
    },
    // AccountDeleteHandle: (state, action) => {
    //   if (action.type === 'Account/AccountDeleteHandle') {
    //     if (action.payload.target === 'course') {
    //       state.AccountDetail.userData.course.splice(action.payload.index, 1)
    //     }
    //     if (action.payload.target === 'Account') {
    //       state.AccountDetail.userData.Account_fee.splice(action.payload.index, 1)
    //     }

    //   }

    // },
    AccountDropdownHandle: (state, action) => {
      if (action.type === 'Account/AccountDropdownHandle') {
        state.AccountDetail.detail[action.payload.target] = action.payload.value
      }
    },
    AccountOnChangeHandle: (state, action) => {
      if (action.type === 'Account/AccountOnChangeHandle') {
        state.AccountDetail.detail[action.payload.target] = action.payload.value
      }
    },
    AccountLoading: (state, action) => {
      if (action.type === 'Account/AccountLoading') {
        state.AccountData.isLoading = true
      }
    },
    AccountAlert: (state, action) => {
      if (action.type === 'Account/AccountAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(AccountCreate.fulfilled, (state, action) => {
        if (action.type === 'Account/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(AccountUpdate.fulfilled, (state, action) => {
        if (action.type === 'Account/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'Account/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.AccountData = action.payload
            state.chart = action.payload.chart
            state.AccountData.isLoading = false
          }
        }
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        if (action.type === 'Account/list/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.name, value: i.list_Account_id })
            }
            state.AccountDetail.dropdown.lists = arr
          }
        }
      })
      .addCase(getDataListCategory.fulfilled, (state, action) => {
        if (action.type === 'Account/list-category/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.category, value: i.category })
            }
            for (const i of arr) {
              const arr_list = []
              for (let j of i?.list_Account) {
                arr_list.push({ ...j, label: j.name, value: j.list_Account_id })
                i.list_Account = arr_list
              }
            }
            state.AccountDetail.dropdown.listsCategory = arr
          }
        }
      })
      .addCase(getDataEmployee.fulfilled, (state, action) => {
        if (action.type === 'Account/employee/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.name, value: i.value })
            }
            state.AccountDetail.dropdown.employees = arr
          }
        }
      })
      .addCase(getDataVehicle.fulfilled, (state, action) => {
        if (action.type === 'Account/vehicle/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.name, value: i.value })
            }
            state.AccountDetail.dropdown.vehicles = arr
          }
        }
      })
      .addCase(getDataVehicleAll.fulfilled, (state, action) => {
        if (action.type === 'Account/vehicle-all/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            const arr = []
            for (const i of action.payload.data) {
              arr.push({ ...i, label: i.name, value: i.value })
            }
            state.AccountDetail.dropdown.vehicles = arr
          }
        }
      })
      .addCase(AccountReaderCard.fulfilled, (state, action) => {
        if (action.type === 'Account/AccountReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.AccountDetail.userData.prefix = action.payload.title_th
            state.AccountDetail.userData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.AccountDetail.userData.idcard = action.payload.nat_id
            state.AccountDetail.userData.birthday = action.payload.birthdate
            state.AccountDetail.userData.address = action.payload.address_no
            state.AccountDetail.userData.street = action.payload.address_road
            state.AccountDetail.userData.province = action.payload.address_provinice
            state.AccountDetail.userData.district = action.payload.address_amphor
            state.AccountDetail.userData.subdistrict = action.payload.address_tumbol
            state.AccountDetail.userData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(AccountDataDetail.fulfilled, (state, action) => {
        if (action.type === 'Account/AccountDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.AccountDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              // if (data === null) data = ''
              if (i === 'pay_type' && data) data = { label: data, value: data }
              if (i === 'list_expense' && data) {

                if (state.AccountDetail.dropdown.lists.length > 0) {
                  let list_Account = JSON.parse(JSON.stringify(state.AccountDetail.dropdown.lists)).find(item => item.list_Account_id === data.list_Account_id)
                  if (list_Account) {
                    state.AccountDetail.detail.list_Account = { ...list_Account, label: list_Account.name, value: list_Account.list_Account_id }
                  } else {
                    state.AccountDetail.detail.list_Account = null
                  }

                } else {
                  state.AccountDetail.detail.list_Account = null
                }
              }
              state.AccountDetail.detail[i] = data
            }
          }
        }
      })
      .addCase(AccountCheckExsit.fulfilled, (state, action) => {
        if (action.type === 'Account/AccountCheckExsit/fulfilled') {
          console.log(action)
          if (action.payload.status === 'success') {
            if (action.payload.data) {
              state.AccountDetail.userData.prefix = action.payload.data.prefix ? action.payload.data.prefix : ''
              state.AccountDetail.userData.name = action.payload.data.name ? action.payload.data.name : ''
              state.AccountDetail.userData.idcard = action.payload.data.idcard ? action.payload.data.idcard : ''
              state.AccountDetail.userData.birthday = action.payload.data.birthday ? action.payload.data.birthday : null
              state.AccountDetail.userData.address = action.payload.data.address ? action.payload.data.address : ''
              state.AccountDetail.userData.moo = action.payload.data.moo ? action.payload.data.moo : ''
              state.AccountDetail.userData.soi = action.payload.data.soi ? action.payload.data.soi : ''
              state.AccountDetail.userData.street = action.payload.data.street ? action.payload.data.street : ''
              state.AccountDetail.userData.province = action.payload.data.province ? action.payload.data.province : ''
              state.AccountDetail.userData.district = action.payload.data.district ? action.payload.data.district : ''
              state.AccountDetail.userData.subdistrict = action.payload.data.subdistrict ? action.payload.data.subdistrict : ''
              state.AccountDetail.userData.phone_1 = action.payload.data.phone_1 ? action.payload.data.phone_1 : ''
              state.AccountDetail.userData.phone_2 = action.payload.data.phone_2 ? action.payload.data.phone_2 : ''
            } else {

            }

          } else {
            //handleError
          }
        }
      })
      .addCase(AccountGetCourse.fulfilled, (state, action) => {
        if (action.type === 'Account/AccountGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.AccountDetail.dropdown.course = action.payload.course
            state.AccountDetail.dropdown.Account_fee = action.payload.Account_fee
          }
        }
      })
      .addCase(AccountPDFCreate.fulfilled, (state, action) => {
        if (action.type === 'Account/accounting/printpdf/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(AccountPDFPaymentCreate.fulfilled, (state, action) => {
        if (action.type === 'Account/applicationfee/pdfpayment/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
  }
})

export const { searchCriteria, AccountNew, AccountAddHandle, AccountDropdownHandle, AccountDeleteHandle, AccountOnChangeHandle, AccountLoading, AccountAlert } = AccountSlice.actions

export default AccountSlice.reducer
