// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../configs/apiConfig'


export const payrollReportCreate = createAsyncThunk('payrollReport/payroll/submit', async (params) => {
  const response = await apiHandle('POST', 'payroll/submit', params)
  return response
})

export const payrollReportUpdate = createAsyncThunk('payrollReport/list-payrollReport/update', async (params) => {
  const response = await apiHandle('PUT', 'list-payrollReport/update', params)
  return response
})

export const payrollReportRefund = createAsyncThunk('payrollReport/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const payrollReportDelete = createAsyncThunk('payrollReport/application/delete', async (params) => {
  const response = await apiHandle('POST', 'application/delete', params)
  return response
})


export const payrollReportPDFCreate = createAsyncThunk('payrollReport/payroll/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'payroll/payslip/pdf', params)
  return response
})

export const payrollReportReaderCard = createAsyncThunk('payrollReport/payrollReportReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const payrollReportGetList = createAsyncThunk('payrollReport/list-payrollReport/list', async (params) => {
  const response = await apiHandle('GET', 'list-payrollReport/list', params)
  return response
})

export const getDataTable = createAsyncThunk('payrollReport/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'payroll/payslip/gettable', params)
  return response
})

export const payrollReportDataDetail = createAsyncThunk('payrollReport/payrollReportDataDetail', async (params) => {
  const response = await apiHandle('GET', 'payroll/userhistory', params)
  return response
})

export const payrollReportGetCourse = createAsyncThunk('payrollReport/payrollReportGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforpayrollReport', params)
  return response
})

export const payrollConfirm = createAsyncThunk('payroll/confirm', async (params) => {
  const response = await apiHandle('POST', 'payroll/confirm', params)
  return response
})

export const payrollApprove = createAsyncThunk('payroll/approve', async (params) => {
  const response = await apiHandle('POST', 'payroll/approve', params)
  return response
})

export const getAllData = createAsyncThunk('payrollReport/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('payrollReport/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('payrollReport/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('payrollReport/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('payrollReport/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultpayrollReportDetail = {
  deduction: [],
  income: [],
  salary: [],
  user_info: null,
  is_approve: null,
  is_payroll: null,
  createStatus: false
}


export const payrollReportSlice = createSlice({
  name: 'payrollReport',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      month: { label: "เดือนล่าสุด", value: "" }
    },
    payrollReportData: {
      data: [],
      filter: {
        branch: [],
        position: [],
        month:[]
      },
      meta: {},
      isLoading: false
    },
    payrollReportDetail: {
      isEdit: false,
      isView: false,
      id: null,
      dropdown: {
        branch: [],
        position: [],
        month:[]
      },
      payrollReportData: defaultpayrollReportDetail,
      defaultListData: defaultpayrollReportDetail
    },
    alert: {
      active: false,
      message: '',
      err: false
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'payrollReport/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    payrollReportNew: (state, action) => {
      if (action.type === 'payrollReport/payrollReportNew') {
        state.payrollReportDetail.isEdit = false
        state.payrollReportDetail.isView = false
        state.payrollReportDetail.payrollReportData = defaultpayrollReportDetail
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    payrollReportAddHandle: (state, action) => {
      if (action.type === 'payrollReport/payrollReportAddHandle') {
        if (action.payload.target === 'course') {
          state.payrollReportDetail.payrollReportData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.payrollReportDetail.payrollReportData.other_fee.push(defaultOtherCost)
        }

      }
    },
    payrollReportDeleteHandle: (state, action) => {
      if (action.type === 'payrollReport/payrollReportDeleteHandle') {
        if (action.payload.target === 'course') {
          state.payrollReportDetail.payrollReportData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.payrollReportDetail.payrollReportData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    payrollReportDropdownHandle: (state, action) => {
      if (action.type === 'payrollReport/payrollReportDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.payrollReportDetail.isEdit === false) {
          state.payrollReportDetail.payrollReportData.course[action.payload.index].course_id = action.payload.value.course_id
          state.payrollReportDetail.payrollReportData.course[action.payload.index].course_name = action.payload.value.name
          state.payrollReportDetail.payrollReportData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.payrollReportDetail.payrollReportData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.payrollReportDetail.isEdit === false) {
          state.payrollReportDetail.payrollReportData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.payrollReportDetail.payrollReportData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.payrollReportDetail.payrollReportData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.payrollReportDetail.payrollReportData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    payrollReportGetData: (state, action) => {
      if (action.type === 'payrollReport/payrollReportGetData') {
        state.payrollReportDetail.payrollReportData = action.payload
      }
    },
    payrollReportOnChangeHandle: (state, action) => {
      if (action.type === 'payrollReport/payrollReportOnChangeHandle') {
        if (action.payload.target === 'per_value') {
          state.payrollReportDetail.payrollReportData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } if (action.payload.target === 'id') {
          state.payrollReportDetail.id = action.payload.value
        } if (action.payload.target === 'isDefault') {
          state.payrollReportDetail.isDefault = action.payload.value
        } if (action.payload.target === 'isEdit') {
          state.payrollReportDetail.isEdit = action.payload.value
        } else {
          state.payrollReportDetail.payrollReportData[action.payload.target] = action.payload.value
        }

      }
    },
    payrollReportLoading: (state, action) => {
      if (action.type === 'payrollReport/payrollReportLoading') {
        state.payrollReportData.isLoading = true
      }
    },
    payrollReportAlert: (state, action) => {
      if (action.type === 'payrollReport/payrollReportAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    payrollReportRefundDelete: (state, action) => {
      if (action.type === 'payrollReport/payrollReportRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    },
    payrollReportEdit: (state, action) => {
      if (action.type === 'payrollReport/payrollReportEdit') {
        state.payrollReportDetail.isEdit = false
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(payrollApprove.fulfilled, (state, action) => {
        if (action.type === 'payroll/approve/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.payrollReportDetail.payrollReportData.createStatus = true
            // state.payrollReportDetail.payrollReportData.pdf = action.payload.data
          }
        }
      })
      .addCase(payrollConfirm.fulfilled, (state, action) => {
        if (action.type === 'payroll/confirm/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.payrollReportDetail.payrollReportData.createStatus = true
            // state.payrollReportDetail.payrollReportData.pdf = action.payload.data
          }
        }
      })
      .addCase(payrollReportCreate.fulfilled, (state, action) => {
        if (action.type === 'payrollReport/payroll/submit/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.payrollReportDetail.payrollReportData.createStatus = true
            // state.payrollReportDetail.payrollReportData.pdf = action.payload.data
          }
        }
      })
      .addCase(payrollReportUpdate.fulfilled, (state, action) => {
        if (action.type === 'payrollReport/list-payrollReport/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.payrollReportDetail.payrollReportData.createStatus = true
            // state.payrollReportDetail.payrollReportData.pdf = action.payload.data
          }
        }
      })
      .addCase(payrollReportRefund.fulfilled, (state, action) => {
        if (action.type === 'payrollReport/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(payrollReportDelete.fulfilled, (state, action) => {
        if (action.type === 'payrollReport/application/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(payrollReportGetList.fulfilled, (state, action) => {
        if (action.type === 'payrollReport/list-payrollReport/list/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            let branch = []
            let category_deduction = []
            let category_income = []
            let condition = []
            let status = []
            let tital_value = []
            let type = []
            if (action.payload.data && Array.isArray(action.payload.data.branch)) {
              for (const i of action.payload.data.branch) {
                branch.push({ value: i.value, label: i.name })
              }
            }
            state.payrollReportDetail.dropdown.branch = branch

            if (action.payload.data && Array.isArray(action.payload.data.category_deduction)) {
              for (const i of action.payload.data.category_deduction) {
                category_deduction.push({ value: i, label: i })
              }
            }
            state.payrollReportDetail.dropdown.category_deduction = category_deduction

            if (action.payload.data && Array.isArray(action.payload.data.category_income)) {
              for (const i of action.payload.data.category_income) {
                category_income.push({ value: i, label: i })
              }
            }
            state.payrollReportDetail.dropdown.category_income = category_income

            if (action.payload.data && Array.isArray(action.payload.data.condition)) {
              for (const i of action.payload.data.condition) {
                let label = 'คูณ'
                if (i === 'fill') {
                  label = 'บวก'
                }
                condition.push({ value: i, label: label })
              }
            }
            state.payrollReportDetail.dropdown.condition = condition

            if (action.payload.data && Array.isArray(action.payload.data.status)) {
              for (const i of action.payload.data.status) {
                status.push({ value: i, label: i })
              }
            }
            state.payrollReportDetail.dropdown.status = status

            if (action.payload.data && Array.isArray(action.payload.data.tital_value)) {
              for (const i of action.payload.data.tital_value) {
                tital_value.push({ value: i, label: i })
              }
            }
            state.payrollReportDetail.dropdown.tital_value = tital_value

            if (action.payload.data && Array.isArray(action.payload.data.type)) {
              for (const i of action.payload.data.type) {
                type.push({ value: i, label: i })
              }
            }
            state.payrollReportDetail.dropdown.type = type
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'payrollReport/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.payrollReportData = action.payload
            state.payrollReportData.isLoading = false
          }
        }
      })
      .addCase(payrollReportDataDetail.fulfilled, (state, action) => {
        if (action.type === 'payrollReport/payrollReportDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            //handleError
          } else {
            state.payrollReportDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              state.payrollReportDetail.payrollReportData[i] = data
              state.payrollReportDetail.defaultListData[i] = data
            }
            state.payrollReportDetail.payrollReportData.is_approve = action.payload.detail.is_approve
            state.payrollReportDetail.payrollReportData.is_payroll = action.payload.detail.is_payroll
          }
        }
      })
      .addCase(payrollReportGetCourse.fulfilled, (state, action) => {
        if (action.type === 'payrollReport/payrollReportGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.payrollReportDetail.dropdown.course = action.payload.course
            state.payrollReportDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(payrollReportReaderCard.fulfilled, (state, action) => {
        if (action.type === 'payrollReport/payrollReportReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.payrollReportDetail.payrollReportData.prefix = action.payload.title_th
            state.payrollReportDetail.payrollReportData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.payrollReportDetail.payrollReportData.idcard = action.payload.nat_id
            state.payrollReportDetail.payrollReportData.birthday = action.payload.birthdate
            state.payrollReportDetail.payrollReportData.address = action.payload.address_no
            state.payrollReportDetail.payrollReportData.street = action.payload.address_road
            state.payrollReportDetail.payrollReportData.province = action.payload.address_provinice
            state.payrollReportDetail.payrollReportData.district = action.payload.address_amphor
            state.payrollReportDetail.payrollReportData.subdistrict = action.payload.address_tumbol
            state.payrollReportDetail.payrollReportData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(payrollReportPDFCreate.fulfilled, (state, action) => {
        if (action.type === 'payrollReport/payroll/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})
export const { searchCriteria, payrollReportNew, payrollReportAddHandle, payrollReportDropdownHandle, payrollReportDeleteHandle, payrollReportOnChangeHandle, payrollReportLoading, payrollReportAlert, payrollReportRefundDelete, payrollReportEdit, payrollReportGetData } = payrollReportSlice.actions

export default payrollReportSlice.reducer
