// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import moment from 'moment'

import apiHandle from '../../../../configs/apiConfig'


export const outstandingBalanceCreate = createAsyncThunk('outstandingBalance/application/create', async (params) => {
  const response = await apiHandle('POST', 'application/paybalance', params)
  return response
})

export const outstandingBalanceUpdate = createAsyncThunk('outstandingBalance/payment/edit-paytype', async (params) => {
  const response = await apiHandle('PUT', 'payment/edit-paytype', params)
  return response
})

export const outstandingBalanceRequestDelete = createAsyncThunk('outstandingBalance/payment/request-delete', async (params) => {
  const response = await apiHandle('POST', 'payment/request-delete', params)
  return response
})

export const outstandingBalanceRefund = createAsyncThunk('outstandingBalance/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const outstandingBalanceDelete = createAsyncThunk('outstandingBalance/application/delete', async (params) => {
  const response = await apiHandle('POST', 'application/delete', params)
  return response
})


export const outstandingBalancePDFCreate = createAsyncThunk('outstandingBalance/payment/pdf', async (params) => {
  const response = await apiHandle('POST', 'payment/pdf ', params)
  return response
})

export const outstandingBalanceReaderCard = createAsyncThunk('outstandingBalance/outstandingBalanceReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const getDataTable = createAsyncThunk('outstandingBalance/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'payment/paybalance/gettable', params)
  return response
})

export const outstandingBalanceDataDetail = createAsyncThunk('outstandingBalance/outstandingBalanceDataDetail', async (params) => {
  const response = await apiHandle('GET', 'application/get', params)
  return response
})

export const outstandingBalanceGetCourse = createAsyncThunk('outstandingBalance/outstandingBalanceGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforoutstandingBalance', params)
  return response
})

export const getAllData = createAsyncThunk('outstandingBalance/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('outstandingBalance/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('outstandingBalance/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('outstandingBalance/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('outstandingBalance/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultCoruseCost = {
  course_id: '',
  course_name: null,
  promotion_code: null,
  exam_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null,
  payment_id: null,
  note: null
}

const defaultOtherCost = {
  other_fee_id: null,
  other_fee_name: null,
  promotion_code: null,
  exam_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultPayments = {
  payment_id: null,
  payment_code: null,
  pay_type: null,
  paid: null,
  createdAt: null
}


const defaultReisterDetail = {
  is_foreigner: false,
  prefix: '',
  name: '',
  idcard: '',
  birthday: null,
  address: null,
  moo: '',
  soi: '',
  street: '',
  subdistrict: '',
  district: '',
  province: '',
  phone_1: '',
  phone_2: '',
  age: '',
  course: [defaultCoruseCost],
  other_fee: [defaultOtherCost],
  payments: [defaultPayments],
  paid: '',
  discount: '',
  net: '',
  balance: '',
  total: '',
  // pay_type: 'เงินสด',
  status: null,
  application_code: null,
  course_id: '',
  createStatus: false,
  application_id: null,
  pdf: []
}


export const outstandingBalanceSlice = createSlice({
  name: 'outstandingBalance',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      user: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      // status: { label: 'ค้างชำระ', value: 'ค้างชำระ' },
      // type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      start: moment(new Date()).format('YYYY-MM-DD'),
      end: moment(new Date()).format('YYYY-MM-DD'),
      text: ''
    },
    outstandingBalanceData: {
      data: [],
      filter: {
        branch: [],
        user: [],
        type: []
      },
      meta: {},
      total: {
        banktransfer: null,
        cash: null
      },
      isLoading: false
    },
    outstandingBalanceDetail: {
      isEdit: false,
      dropdown: {
        course: [],
        other_fee: []
      },
      outstandingBalanceData: defaultReisterDetail
    },
    alert: {
      active: false,
      message: '',
      err: false
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'outstandingBalance/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    outstandingBalanceNew: (state, action) => {
      if (action.type === 'outstandingBalance/outstandingBalanceNew') {
        state.outstandingBalanceDetail.isEdit = false
        state.outstandingBalanceDetail.outstandingBalanceData = defaultReisterDetail
      }
    },
    outstandingBalanceAddHandle: (state, action) => {
      if (action.type === 'outstandingBalance/outstandingBalanceAddHandle') {
        if (action.payload.target === 'course') {
          state.outstandingBalanceDetail.outstandingBalanceData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.outstandingBalanceDetail.outstandingBalanceData.other_fee.push(defaultOtherCost)
        }

      }
    },
    outstandingBalanceDeleteHandle: (state, action) => {
      if (action.type === 'outstandingBalance/outstandingBalanceDeleteHandle') {
        if (action.payload.target === 'course') {
          state.outstandingBalanceDetail.outstandingBalanceData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.outstandingBalanceDetail.outstandingBalanceData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    outstandingBalanceDropdownHandle: (state, action) => {
      if (action.type === 'outstandingBalance/outstandingBalanceDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.outstandingBalanceDetail.isEdit === false) {
          state.outstandingBalanceDetail.outstandingBalanceData.course[action.payload.index].course_id = action.payload.value.course_id
          state.outstandingBalanceDetail.outstandingBalanceData.course[action.payload.index].course_name = action.payload.value.name
          state.outstandingBalanceDetail.outstandingBalanceData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.outstandingBalanceDetail.outstandingBalanceData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.outstandingBalanceDetail.isEdit === false) {
          state.outstandingBalanceDetail.outstandingBalanceData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.outstandingBalanceDetail.outstandingBalanceData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.outstandingBalanceDetail.outstandingBalanceData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.outstandingBalanceDetail.outstandingBalanceData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    outstandingBalanceOnChangeHandle: (state, action) => {
      if (action.type === 'outstandingBalance/outstandingBalanceOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.outstandingBalanceDetail.outstandingBalanceData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.outstandingBalanceDetail.outstandingBalanceData[action.payload.target] = action.payload.value
        }

      }
    },
    outstandingBalanceLoading: (state, action) => {
      if (action.type === 'outstandingBalance/outstandingBalanceLoading') {
        state.outstandingBalanceData.isLoading = true
      }
    },
    outstandingBalanceAlert: (state, action) => {
      if (action.type === 'outstandingBalance/outstandingBalanceAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    outstandingBalanceRefundDelete: (state, action) => {
      if (action.type === 'outstandingBalance/outstandingBalanceRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(outstandingBalanceCreate.fulfilled, (state, action) => {
        if (action.type === 'outstandingBalance/application/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(outstandingBalanceRequestDelete.fulfilled, (state, action) => {
        if (action.type === 'outstandingBalance/payment/request-delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(outstandingBalanceUpdate.fulfilled, (state, action) => {
        if (action.type === 'outstandingBalance/payment/edit-paytype/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(outstandingBalanceRefund.fulfilled, (state, action) => {
        if (action.type === 'outstandingBalance/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(outstandingBalanceDelete.fulfilled, (state, action) => {
        if (action.type === 'outstandingBalance/application/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'outstandingBalance/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.outstandingBalanceData = action.payload
            state.outstandingBalanceData.isLoading = false
          }
        }
      })
      .addCase(outstandingBalanceDataDetail.fulfilled, (state, action) => {
        if (action.type === 'outstandingBalance/outstandingBalanceDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            console.log(action)
            state.outstandingBalanceDetail.isEdit = true
            state.outstandingBalanceDetail.outstandingBalanceData.prefix = action.payload.data.customer.prefix ? action.payload.data.customer.prefix : ''
            state.outstandingBalanceDetail.outstandingBalanceData.name = action.payload.data.customer.name ? action.payload.data.customer.name : ''
            state.outstandingBalanceDetail.outstandingBalanceData.idcard = action.payload.data.customer.idcard ? action.payload.data.customer.idcard : ''
            state.outstandingBalanceDetail.outstandingBalanceData.birthday = action.payload.data.customer.birthday ? action.payload.data.customer.birthday : null
            state.outstandingBalanceDetail.outstandingBalanceData.address = action.payload.data.customer.address ? action.payload.data.customer.address : ''
            state.outstandingBalanceDetail.outstandingBalanceData.moo = action.payload.data.customer.moo ? action.payload.data.customer.moo : ''
            state.outstandingBalanceDetail.outstandingBalanceData.soi = action.payload.data.customer.soi ? action.payload.data.customer.soi : ''
            state.outstandingBalanceDetail.outstandingBalanceData.street = action.payload.data.customer.street ? action.payload.data.customer.street : ''
            state.outstandingBalanceDetail.outstandingBalanceData.province = action.payload.data.customer.province ? action.payload.data.customer.province : ''
            state.outstandingBalanceDetail.outstandingBalanceData.district = action.payload.data.customer.district ? action.payload.data.customer.district : ''
            state.outstandingBalanceDetail.outstandingBalanceData.subdistrict = action.payload.data.customer.subdistrict ? action.payload.data.customer.subdistrict : ''
            state.outstandingBalanceDetail.outstandingBalanceData.phone_1 = action.payload.data.customer.phone_1 ? action.payload.data.customer.phone_1 : ''
            state.outstandingBalanceDetail.outstandingBalanceData.phone_2 = action.payload.data.customer.phone_2 ? action.payload.data.customer.phone_2 : ''
            state.outstandingBalanceDetail.outstandingBalanceData.status = action.payload.data.status ? action.payload.data.status : null
            state.outstandingBalanceDetail.outstandingBalanceData.application_code = action.payload.data.application_code ? action.payload.data.application_code : null
            state.outstandingBalanceDetail.outstandingBalanceData.paid = action.payload.data.paid === 0 ? '0' : action.payload.data.paid
            state.outstandingBalanceDetail.outstandingBalanceData.course_id = action.payload.data.paid ? action.payload.data.course_id.toString() : ''
            state.outstandingBalanceDetail.outstandingBalanceData.discount = action.payload.data.discount === 0 ? '0' : action.payload.data.discount.toString()
            state.outstandingBalanceDetail.outstandingBalanceData.net = action.payload.data.net === 0 ? '0' : action.payload.data.net.toString()
            state.outstandingBalanceDetail.outstandingBalanceData.balance = action.payload.data.balance === 0 ? '0' : action.payload.data.balance.toString()
            state.outstandingBalanceDetail.outstandingBalanceData.total = action.payload.data.total === 0 ? '0' : action.payload.data.total.toString()
            state.outstandingBalanceDetail.outstandingBalanceData.payments = action.payload.data.payments
            state.outstandingBalanceDetail.outstandingBalanceData.application_id = action.payload.data.application_id
            state.outstandingBalanceDetail.outstandingBalanceData.is_foreigner = action.payload.data.customer.is_foreigner
            state.outstandingBalanceDetail.outstandingBalanceData.course = [
              {
                course_id: action.payload.data.course_id ? action.payload.data.course_id.toString() : '',
                course_name: null,
                promotion_code: null,
                exam_date: null,
                cost: action.payload.data.balance ? action.payload.data.balance.toString() : '',
                discount: null,
                paid: null,
                pay_type: null
              }
            ]
          }
        }
      })
      .addCase(outstandingBalanceGetCourse.fulfilled, (state, action) => {
        if (action.type === 'outstandingBalance/outstandingBalanceGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.outstandingBalanceDetail.dropdown.course = action.payload.course
            state.outstandingBalanceDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(outstandingBalanceReaderCard.fulfilled, (state, action) => {
        if (action.type === 'outstandingBalance/outstandingBalanceReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.outstandingBalanceDetail.outstandingBalanceData.prefix = action.payload.title_th
            state.outstandingBalanceDetail.outstandingBalanceData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.outstandingBalanceDetail.outstandingBalanceData.idcard = action.payload.nat_id
            state.outstandingBalanceDetail.outstandingBalanceData.birthday = action.payload.birthdate
            state.outstandingBalanceDetail.outstandingBalanceData.address = action.payload.address_no
            state.outstandingBalanceDetail.outstandingBalanceData.street = action.payload.address_road
            state.outstandingBalanceDetail.outstandingBalanceData.province = action.payload.address_provinice
            state.outstandingBalanceDetail.outstandingBalanceData.district = action.payload.address_amphor
            state.outstandingBalanceDetail.outstandingBalanceData.subdistrict = action.payload.address_tumbol
            state.outstandingBalanceDetail.outstandingBalanceData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(outstandingBalancePDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'outstandingBalance/payment/pdf/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, outstandingBalanceNew, outstandingBalanceAddHandle, outstandingBalanceDropdownHandle, outstandingBalanceDeleteHandle, outstandingBalanceOnChangeHandle, outstandingBalanceLoading, outstandingBalanceAlert, outstandingBalanceRefundDelete } = outstandingBalanceSlice.actions

export default outstandingBalanceSlice.reducer
