// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../../configs/apiConfig'


export const manageDiscountCreate = createAsyncThunk('manageDiscount/promotion/create', async (params) => {
  const response = await apiHandle('POST', 'promotion/create', params)
  return response
})

export const manageDiscountUpdate = createAsyncThunk('manageDiscount/promotion/update', async (params) => {
  const response = await apiHandle('PUT', 'promotion/update', params)
  return response
})

export const manageDiscountRefund = createAsyncThunk('manageDiscount/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const manageDiscountDelete = createAsyncThunk('manageDiscount/promotion/delete', async (params) => {
  const response = await apiHandle('DELETE', 'promotion/delete', params)
  return response
})


export const manageDiscountPDFCreate = createAsyncThunk('manageDiscount/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const manageDiscountReaderCard = createAsyncThunk('manageDiscount/manageDiscountReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const manageDiscountGetList = createAsyncThunk('manageDiscount/promotion/type', async (params) => {
  const response = await apiHandle('GET', 'promotion/type', params)
  return response
})

export const getDataTable = createAsyncThunk('manageDiscount/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'promotion/gettable', params)
  return response
})

export const manageDiscountDataDetail = createAsyncThunk('manageDiscount/manageDiscountDataDetail', async (params) => {
  const response = await apiHandle('GET', 'promotion/get', params)
  return response
})

export const manageDiscountGetCourse = createAsyncThunk('manageDiscount/manageDiscountGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getformanageDiscount', params)
  return response
})

export const getAllData = createAsyncThunk('manageDiscount/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('manageDiscount/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('manageDiscount/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('manageDiscount/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('manageDiscount/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultDiscountDetail = {
  branch_id: '',
  code:'',
  createdAt: '',
  detail: '',
  end: '',
  is_activate: '',
  is_delete: '',
  is_unlimit: '',
  limit: '',
  name: '',
  promotion_id: '',
  start: '',
  type: '',
  type_discount: 'percent',
  updatedAt: '',
  usage: '',
  value: '',
  createStatus: false
}


export const manageDiscountSlice = createSlice({
  name: 'manageDiscount',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    manageDiscountData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    manageDiscountDetail: {
      isEdit: false,
      dropdown: {
        type: [],
        status: [
          { label: 'เปิดใช้งาน', value: true },
          { label: 'ปิดใช้งาน', value: false }
        ]
      },
      discountData: defaultDiscountDetail
    },
    alert: {
      active: false,
      message: '',
      err:false
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'manageDiscount/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    manageDiscountNew: (state, action) => {
      if (action.type === 'manageDiscount/manageDiscountNew') {
        state.manageDiscountDetail.isEdit = false
        state.manageDiscountDetail.discountData = defaultDiscountDetail
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageDiscountAddHandle: (state, action) => {
      if (action.type === 'manageDiscount/manageDiscountAddHandle') {
        if (action.payload.target === 'course') {
          state.manageDiscountDetail.discountData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.manageDiscountDetail.discountData.other_fee.push(defaultOtherCost)
        }

      }
    },
    manageDiscountDeleteHandle: (state, action) => {
      if (action.type === 'manageDiscount/manageDiscountDeleteHandle') {
        if (action.payload.target === 'course') {
          state.manageDiscountDetail.discountData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.manageDiscountDetail.discountData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    manageDiscountDropdownHandle: (state, action) => {
      if (action.type === 'manageDiscount/manageDiscountDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.manageDiscountDetail.isEdit === false) {
          state.manageDiscountDetail.discountData.course[action.payload.index].course_id = action.payload.value.course_id
          state.manageDiscountDetail.discountData.course[action.payload.index].course_name = action.payload.value.name
          state.manageDiscountDetail.discountData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.manageDiscountDetail.discountData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.manageDiscountDetail.isEdit === false) {
          state.manageDiscountDetail.discountData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.manageDiscountDetail.discountData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.manageDiscountDetail.discountData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.manageDiscountDetail.discountData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    manageDiscountOnChangeHandle: (state, action) => {
      if (action.type === 'manageDiscount/manageDiscountOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.manageDiscountDetail.discountData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.manageDiscountDetail.discountData[action.payload.target] = action.payload.value
        }

      }
    },
    manageDiscountLoading: (state, action) => {
      if (action.type === 'manageDiscount/manageDiscountLoading') {
        state.manageDiscountData.isLoading = true
      }
    },
    manageDiscountAlert: (state, action) => {
      if (action.type === 'manageDiscount/manageDiscountAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageDiscountRefundDelete: (state, action) => {
      if (action.type === 'manageDiscount/manageDiscountRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(manageDiscountCreate.fulfilled, (state, action) => {
        if (action.type === 'manageDiscount/promotion/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.manageDiscountDetail.discountData.createStatus = true
            // state.manageDiscountDetail.discountData.pdf = action.payload.data
          }
        }
      })
      .addCase(manageDiscountUpdate.fulfilled, (state, action) => {
        if (action.type === 'manageDiscount/promotion/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            console.log(action)
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.manageDiscountDetail.discountData.createStatus = true
            // state.manageDiscountDetail.discountData.pdf = action.payload.data
          }
        }
      })
      .addCase(manageDiscountGetList.fulfilled, (state, action) => {
        if (action.type === 'manageDiscount/promotion/type/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            let arr = []
            if (Array.isArray(action.payload.data)) {
              for (const i of action.payload.data) {
                arr.push({ value: i, label: i })
              }
            }
            state.manageDiscountDetail.dropdown.type = arr
          }
        }
      })
      .addCase(manageDiscountRefund.fulfilled, (state, action) => {
        if (action.type === 'manageDiscount/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(manageDiscountDelete.fulfilled, (state, action) => {
        if (action.type === 'manageDiscount/promotion/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'manageDiscount/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageDiscountData = action.payload
            state.manageDiscountData.isLoading = false
          }
        }
      })
      .addCase(manageDiscountDataDetail.fulfilled, (state, action) => {
        if (action.type === 'manageDiscount/manageDiscountDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            console.log(action)
            state.manageDiscountDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              if (data === null) data = ''
              state.manageDiscountDetail.discountData[i] = data
            }
          }
        }
      })
      .addCase(manageDiscountGetCourse.fulfilled, (state, action) => {
        if (action.type === 'manageDiscount/manageDiscountGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageDiscountDetail.dropdown.course = action.payload.course
            state.manageDiscountDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(manageDiscountReaderCard.fulfilled, (state, action) => {
        if (action.type === 'manageDiscount/manageDiscountReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.manageDiscountDetail.discountData.prefix = action.payload.title_th
            state.manageDiscountDetail.discountData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.manageDiscountDetail.discountData.idcard = action.payload.nat_id
            state.manageDiscountDetail.discountData.birthday = action.payload.birthdate
            state.manageDiscountDetail.discountData.address = action.payload.address_no
            state.manageDiscountDetail.discountData.street = action.payload.address_road
            state.manageDiscountDetail.discountData.province = action.payload.address_provinice
            state.manageDiscountDetail.discountData.district = action.payload.address_amphor
            state.manageDiscountDetail.discountData.subdistrict = action.payload.address_tumbol
            state.manageDiscountDetail.discountData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(manageDiscountPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'manageDiscount/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, manageDiscountNew, manageDiscountAddHandle, manageDiscountDropdownHandle, manageDiscountDeleteHandle, manageDiscountOnChangeHandle, manageDiscountLoading, manageDiscountAlert, manageDiscountRefundDelete } = manageDiscountSlice.actions

export default manageDiscountSlice.reducer
