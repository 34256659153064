// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../../configs/apiConfig'


export const payrollListUserCreate = createAsyncThunk('payrollListUser/list-payrollListUser/create', async (params) => {
  const response = await apiHandle('POST', 'list-payrollListUser/create', params)
  return response
})

export const payrollListUserUpdate = createAsyncThunk('payrollListUser/list-payrollListUser/update', async (params) => {
  const response = await apiHandle('PUT', 'list-payrollListUser/update', params)
  return response
})

export const payrollListUserRefund = createAsyncThunk('payrollListUser/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const payrollListUserDelete = createAsyncThunk('payrollListUser/application/delete', async (params) => {
  const response = await apiHandle('POST', 'application/delete', params)
  return response
})


export const payrollListUserPDFCreate = createAsyncThunk('payrollListUser/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const payrollListUserReaderCard = createAsyncThunk('payrollListUser/payrollListUserReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const payrollListUserGetList = createAsyncThunk('payrollListUser/list-payrollListUser/list', async (params) => {
  const response = await apiHandle('GET', 'list-payrollListUser/list', params)
  return response
})

export const getDataTable = createAsyncThunk('payrollListUser/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'user/gettable', params)
  return response
})

export const payrollListUserDataDetail = createAsyncThunk('payrollListUser/payrollListUserDataDetail', async (params) => {
  const response = await apiHandle('GET', 'payroll/user', params)
  return response
})

export const payrollListUserGetCourse = createAsyncThunk('payrollListUser/payrollListUserGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforpayrollListUser', params)
  return response
})

export const getAllData = createAsyncThunk('payrollListUser/getAllData', async () => {
  const response = await axios.get('/user/gettable')
  return response.data
})

export const getData = createAsyncThunk('payrollListUser/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('payrollListUser/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('payrollListUser/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('payrollListUser/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultpayrollListUserDetail = {
  deduction: [],
  income: [],
  salary: [],
  user_info: null,
  createStatus: false
}


export const payrollListUserSlice = createSlice({
  name: 'payrollListUser',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ค้างชำระ', value: 'ค้างชำระ' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    payrollListUserData: {
      data: [],
      filter: {
        branch: [],
        position:[]
      },
      meta: {},
      isLoading: false
    },
    payrollListUserDetail: {
      isEdit: false,
      dropdown: {
        branch: [],
        position:[]
      },
      payrollListUserData: defaultpayrollListUserDetail
    },
    alert: {
      active: false,
      message: ''
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'payrollListUser/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    payrollListUserNew: (state, action) => {
      if (action.type === 'payrollListUser/payrollListUserNew') {
        state.payrollListUserDetail.isEdit = false
        state.payrollListUserDetail.payrollListUserData = defaultpayrollListUserDetail
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    payrollListUserAddHandle: (state, action) => {
      if (action.type === 'payrollListUser/payrollListUserAddHandle') {
        if (action.payload.target === 'course') {
          state.payrollListUserDetail.payrollListUserData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.payrollListUserDetail.payrollListUserData.other_fee.push(defaultOtherCost)
        }

      }
    },
    payrollListUserDeleteHandle: (state, action) => {
      if (action.type === 'payrollListUser/payrollListUserDeleteHandle') {
        if (action.payload.target === 'course') {
          state.payrollListUserDetail.payrollListUserData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.payrollListUserDetail.payrollListUserData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    payrollListUserDropdownHandle: (state, action) => {
      if (action.type === 'payrollListUser/payrollListUserDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.payrollListUserDetail.isEdit === false) {
          state.payrollListUserDetail.payrollListUserData.course[action.payload.index].course_id = action.payload.value.course_id
          state.payrollListUserDetail.payrollListUserData.course[action.payload.index].course_name = action.payload.value.name
          state.payrollListUserDetail.payrollListUserData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.payrollListUserDetail.payrollListUserData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.payrollListUserDetail.isEdit === false) {
          state.payrollListUserDetail.payrollListUserData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.payrollListUserDetail.payrollListUserData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.payrollListUserDetail.payrollListUserData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.payrollListUserDetail.payrollListUserData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    payrollListUserOnChangeHandle: (state, action) => {
      if (action.type === 'payrollListUser/payrollListUserOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.payrollListUserDetail.payrollListUserData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.payrollListUserDetail.payrollListUserData[action.payload.target] = action.payload.value
        }

      }
    },
    payrollListUserLoading: (state, action) => {
      if (action.type === 'payrollListUser/payrollListUserLoading') {
        state.payrollListUserData.isLoading = true
      }
    },
    payrollListUserAlert: (state, action) => {
      if (action.type === 'payrollListUser/payrollListUserAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    payrollListUserRefundDelete: (state, action) => {
      if (action.type === 'payrollListUser/payrollListUserRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(payrollListUserCreate.fulfilled, (state, action) => {
        if (action.type === 'payrollListUser/list-payrollListUser/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.payrollListUserDetail.payrollListUserData.createStatus = true
            // state.payrollListUserDetail.payrollListUserData.pdf = action.payload.data
          }
        }
      })
      .addCase(payrollListUserUpdate.fulfilled, (state, action) => {
        if (action.type === 'payrollListUser/list-payrollListUser/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.payrollListUserDetail.payrollListUserData.createStatus = true
            // state.payrollListUserDetail.payrollListUserData.pdf = action.payload.data
          }
        }
      })
      .addCase(payrollListUserRefund.fulfilled, (state, action) => {
        if (action.type === 'payrollListUser/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(payrollListUserDelete.fulfilled, (state, action) => {
        if (action.type === 'payrollListUser/application/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(payrollListUserGetList.fulfilled, (state, action) => {
        if (action.type === 'payrollListUser/list-payrollListUser/list/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            let branch = []
            let category_deduction = []
            let category_income = []
            let condition = []
            let status = []
            let tital_value = []
            let type = []
            if (action.payload.data && Array.isArray(action.payload.data.branch)) {
              for (const i of action.payload.data.branch) {
                branch.push({ value: i.value, label: i.name })
              }
            }
            state.payrollListUserDetail.dropdown.branch = branch

            if (action.payload.data && Array.isArray(action.payload.data.category_deduction)) {
              for (const i of action.payload.data.category_deduction) {
                category_deduction.push({ value: i, label: i })
              }
            }
            state.payrollListUserDetail.dropdown.category_deduction = category_deduction

            if (action.payload.data && Array.isArray(action.payload.data.category_income)) {
              for (const i of action.payload.data.category_income) {
                category_income.push({ value: i, label: i })
              }
            }
            state.payrollListUserDetail.dropdown.category_income = category_income

            if (action.payload.data && Array.isArray(action.payload.data.condition)) {
              for (const i of action.payload.data.condition) {
                let label = 'คูณ'
                if (i === 'fill') {
                  label = 'บวก'
                }
                condition.push({ value: i, label: label })
              }
            }
            state.payrollListUserDetail.dropdown.condition = condition

            if (action.payload.data && Array.isArray(action.payload.data.status)) {
              for (const i of action.payload.data.status) {
                status.push({ value: i, label: i })
              }
            }
            state.payrollListUserDetail.dropdown.status = status

            if (action.payload.data && Array.isArray(action.payload.data.tital_value)) {
              for (const i of action.payload.data.tital_value) {
                tital_value.push({ value: i, label: i })
              }
            }
            state.payrollListUserDetail.dropdown.tital_value = tital_value

            if (action.payload.data && Array.isArray(action.payload.data.type)) {
              for (const i of action.payload.data.type) {
                type.push({ value: i, label: i })
              }
            }
            state.payrollListUserDetail.dropdown.type = type
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'payrollListUser/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.payrollListUserData = action.payload
            state.payrollListUserData.isLoading = false
          }
        }
      })
      .addCase(payrollListUserDataDetail.fulfilled, (state, action) => {
        if (action.type === 'payrollListUser/payrollListUserDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            //handleError
          } else {
            state.payrollListUserDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              state.payrollListUserDetail.payrollListUserData[i] = data
            }
          }
        }
      })
      .addCase(payrollListUserGetCourse.fulfilled, (state, action) => {
        if (action.type === 'payrollListUser/payrollListUserGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.payrollListUserDetail.dropdown.course = action.payload.course
            state.payrollListUserDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(payrollListUserReaderCard.fulfilled, (state, action) => {
        if (action.type === 'payrollListUser/payrollListUserReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.payrollListUserDetail.payrollListUserData.prefix = action.payload.title_th
            state.payrollListUserDetail.payrollListUserData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.payrollListUserDetail.payrollListUserData.idcard = action.payload.nat_id
            state.payrollListUserDetail.payrollListUserData.birthday = action.payload.birthdate
            state.payrollListUserDetail.payrollListUserData.address = action.payload.address_no
            state.payrollListUserDetail.payrollListUserData.street = action.payload.address_road
            state.payrollListUserDetail.payrollListUserData.province = action.payload.address_provinice
            state.payrollListUserDetail.payrollListUserData.district = action.payload.address_amphor
            state.payrollListUserDetail.payrollListUserData.subdistrict = action.payload.address_tumbol
            state.payrollListUserDetail.payrollListUserData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(payrollListUserPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'payrollListUser/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, payrollListUserNew, payrollListUserAddHandle, payrollListUserDropdownHandle, payrollListUserDeleteHandle, payrollListUserOnChangeHandle, payrollListUserLoading, payrollListUserAlert, payrollListUserRefundDelete } = payrollListUserSlice.actions

export default payrollListUserSlice.reducer
