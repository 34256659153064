// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import moment from 'moment'

import apiHandle from '../../../../configs/apiConfig'


export const paymentHistoryCreate = createAsyncThunk('paymentHistory/application/create', async (params) => {
  const response = await apiHandle('POST', 'application/paybalance', params)
  return response
})

export const paymentHistoryUpdate = createAsyncThunk('paymentHistory/payment/edit-paytype', async (params) => {
  const response = await apiHandle('PUT', 'payment/edit-paytype', params)
  return response
})

export const paymentHistoryRequestDelete = createAsyncThunk('paymentHistory/payment/request-delete', async (params) => {
  const response = await apiHandle('POST', 'payment/request-delete', params)
  return response
})

export const paymentHistoryRefund = createAsyncThunk('paymentHistory/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const paymentHistoryDelete = createAsyncThunk('paymentHistory/application/delete', async (params) => {
  const response = await apiHandle('POST', 'application/delete', params)
  return response
})


export const paymentHistoryPDFCreate = createAsyncThunk('paymentHistory/payment/pdf', async (params) => {
  const response = await apiHandle('POST', 'payment/pdf ', params)
  return response
})

export const paymentHistoryReaderCard = createAsyncThunk('paymentHistory/paymentHistoryReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const getDataTable = createAsyncThunk('paymentHistory/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'payment/gettable', params)
  return response
})

export const paymentHistoryDataDetail = createAsyncThunk('paymentHistory/paymentHistoryDataDetail', async (params) => {
  const response = await apiHandle('GET', 'application/get', params)
  return response
})

export const paymentHistoryGetCourse = createAsyncThunk('paymentHistory/paymentHistoryGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforpaymentHistory', params)
  return response
})

export const getAllData = createAsyncThunk('paymentHistory/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('paymentHistory/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('paymentHistory/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('paymentHistory/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('paymentHistory/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultCoruseCost = {
  course_id: '',
  course_name: null,
  promotion_code: null,
  exam_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null,
  payment_id: null,
  note: null
}

const defaultOtherCost = {
  other_fee_id: null,
  other_fee_name: null,
  promotion_code: null,
  exam_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultPayments = {
  payment_id: null,
  payment_code: null,
  pay_type: null,
  paid: null,
  createdAt: null
}


const defaultReisterDetail = {
  is_foreigner: false,
  prefix: '',
  name: '',
  idcard: '',
  birthday: null,
  address: null,
  moo: '',
  soi: '',
  street: '',
  subdistrict: '',
  district: '',
  province: '',
  phone_1: '',
  phone_2: '',
  age: '',
  course: [defaultCoruseCost],
  other_fee: [defaultOtherCost],
  payments: [defaultPayments],
  paid: '',
  discount: '',
  net: '',
  balance: '',
  total: '',
  // pay_type: 'เงินสด',
  status: null,
  application_code: null,
  course_id: '',
  createStatus: false,
  application_id: null,
  pdf: []
}


export const paymentHistorySlice = createSlice({
  name: 'paymentHistory',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      user: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      // status: { label: 'ค้างชำระ', value: 'ค้างชำระ' },
      // type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      start: moment(new Date()).format('YYYY-MM-DD'),
      end: moment(new Date()).format('YYYY-MM-DD'),
      text: ''
    },
    paymentHistoryData: {
      data: [],
      filter: {
        branch: [],
        user: [],
        type: []
      },
      meta: {},
      total: {
        banktransfer: null,
        cash: null
      },
      isLoading: false
    },
    paymentHistoryDetail: {
      isEdit: false,
      dropdown: {
        course: [],
        other_fee: []
      },
      paymentHistoryData: defaultReisterDetail
    },
    alert: {
      active: false,
      message: '',
      err: false
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'paymentHistory/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    paymentHistoryNew: (state, action) => {
      if (action.type === 'paymentHistory/paymentHistoryNew') {
        state.paymentHistoryDetail.isEdit = false
        state.paymentHistoryDetail.paymentHistoryData = defaultReisterDetail
      }
    },
    paymentHistoryAddHandle: (state, action) => {
      if (action.type === 'paymentHistory/paymentHistoryAddHandle') {
        if (action.payload.target === 'course') {
          state.paymentHistoryDetail.paymentHistoryData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.paymentHistoryDetail.paymentHistoryData.other_fee.push(defaultOtherCost)
        }

      }
    },
    paymentHistoryDeleteHandle: (state, action) => {
      if (action.type === 'paymentHistory/paymentHistoryDeleteHandle') {
        if (action.payload.target === 'course') {
          state.paymentHistoryDetail.paymentHistoryData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.paymentHistoryDetail.paymentHistoryData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    paymentHistoryDropdownHandle: (state, action) => {
      if (action.type === 'paymentHistory/paymentHistoryDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.paymentHistoryDetail.isEdit === false) {
          state.paymentHistoryDetail.paymentHistoryData.course[action.payload.index].course_id = action.payload.value.course_id
          state.paymentHistoryDetail.paymentHistoryData.course[action.payload.index].course_name = action.payload.value.name
          state.paymentHistoryDetail.paymentHistoryData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.paymentHistoryDetail.paymentHistoryData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.paymentHistoryDetail.isEdit === false) {
          state.paymentHistoryDetail.paymentHistoryData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.paymentHistoryDetail.paymentHistoryData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.paymentHistoryDetail.paymentHistoryData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.paymentHistoryDetail.paymentHistoryData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    paymentHistoryOnChangeHandle: (state, action) => {
      if (action.type === 'paymentHistory/paymentHistoryOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.paymentHistoryDetail.paymentHistoryData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.paymentHistoryDetail.paymentHistoryData[action.payload.target] = action.payload.value
        }

      }
    },
    paymentHistoryLoading: (state, action) => {
      if (action.type === 'paymentHistory/paymentHistoryLoading') {
        state.paymentHistoryData.isLoading = true
      }
    },
    paymentHistoryAlert: (state, action) => {
      if (action.type === 'paymentHistory/paymentHistoryAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    paymentHistoryRefundDelete: (state, action) => {
      if (action.type === 'paymentHistory/paymentHistoryRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(paymentHistoryCreate.fulfilled, (state, action) => {
        if (action.type === 'paymentHistory/application/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(paymentHistoryRequestDelete.fulfilled, (state, action) => {
        if (action.type === 'paymentHistory/payment/request-delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(paymentHistoryUpdate.fulfilled, (state, action) => {
        if (action.type === 'paymentHistory/payment/edit-paytype/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(paymentHistoryRefund.fulfilled, (state, action) => {
        if (action.type === 'paymentHistory/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(paymentHistoryDelete.fulfilled, (state, action) => {
        if (action.type === 'paymentHistory/application/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'paymentHistory/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.paymentHistoryData = action.payload
            state.paymentHistoryData.isLoading = false
          }
        }
      })
      .addCase(paymentHistoryDataDetail.fulfilled, (state, action) => {
        if (action.type === 'paymentHistory/paymentHistoryDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            console.log(action)
            state.paymentHistoryDetail.isEdit = true
            state.paymentHistoryDetail.paymentHistoryData.prefix = action.payload.data.customer.prefix ? action.payload.data.customer.prefix : ''
            state.paymentHistoryDetail.paymentHistoryData.name = action.payload.data.customer.name ? action.payload.data.customer.name : ''
            state.paymentHistoryDetail.paymentHistoryData.idcard = action.payload.data.customer.idcard ? action.payload.data.customer.idcard : ''
            state.paymentHistoryDetail.paymentHistoryData.birthday = action.payload.data.customer.birthday ? action.payload.data.customer.birthday : null
            state.paymentHistoryDetail.paymentHistoryData.address = action.payload.data.customer.address ? action.payload.data.customer.address : ''
            state.paymentHistoryDetail.paymentHistoryData.moo = action.payload.data.customer.moo ? action.payload.data.customer.moo : ''
            state.paymentHistoryDetail.paymentHistoryData.soi = action.payload.data.customer.soi ? action.payload.data.customer.soi : ''
            state.paymentHistoryDetail.paymentHistoryData.street = action.payload.data.customer.street ? action.payload.data.customer.street : ''
            state.paymentHistoryDetail.paymentHistoryData.province = action.payload.data.customer.province ? action.payload.data.customer.province : ''
            state.paymentHistoryDetail.paymentHistoryData.district = action.payload.data.customer.district ? action.payload.data.customer.district : ''
            state.paymentHistoryDetail.paymentHistoryData.subdistrict = action.payload.data.customer.subdistrict ? action.payload.data.customer.subdistrict : ''
            state.paymentHistoryDetail.paymentHistoryData.phone_1 = action.payload.data.customer.phone_1 ? action.payload.data.customer.phone_1 : ''
            state.paymentHistoryDetail.paymentHistoryData.phone_2 = action.payload.data.customer.phone_2 ? action.payload.data.customer.phone_2 : ''
            state.paymentHistoryDetail.paymentHistoryData.status = action.payload.data.status ? action.payload.data.status : null
            state.paymentHistoryDetail.paymentHistoryData.application_code = action.payload.data.application_code ? action.payload.data.application_code : null
            state.paymentHistoryDetail.paymentHistoryData.paid = action.payload.data.paid === 0 ? '0' : action.payload.data.paid
            state.paymentHistoryDetail.paymentHistoryData.course_id = action.payload.data.paid ? action.payload.data.course_id.toString() : ''
            state.paymentHistoryDetail.paymentHistoryData.discount = action.payload.data.discount === 0 ? '0' : action.payload.data.discount.toString()
            state.paymentHistoryDetail.paymentHistoryData.net = action.payload.data.net === 0 ? '0' : action.payload.data.net.toString()
            state.paymentHistoryDetail.paymentHistoryData.balance = action.payload.data.balance === 0 ? '0' : action.payload.data.balance.toString()
            state.paymentHistoryDetail.paymentHistoryData.total = action.payload.data.total === 0 ? '0' : action.payload.data.total.toString()
            state.paymentHistoryDetail.paymentHistoryData.payments = action.payload.data.payments
            state.paymentHistoryDetail.paymentHistoryData.application_id = action.payload.data.application_id
            state.paymentHistoryDetail.paymentHistoryData.is_foreigner = action.payload.data.customer.is_foreigner
            state.paymentHistoryDetail.paymentHistoryData.course = [
              {
                course_id: action.payload.data.course_id ? action.payload.data.course_id.toString() : '',
                course_name: null,
                promotion_code: null,
                exam_date: null,
                cost: action.payload.data.balance ? action.payload.data.balance.toString() : '',
                discount: null,
                paid: null,
                pay_type: null
              }
            ]
          }
        }
      })
      .addCase(paymentHistoryGetCourse.fulfilled, (state, action) => {
        if (action.type === 'paymentHistory/paymentHistoryGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.paymentHistoryDetail.dropdown.course = action.payload.course
            state.paymentHistoryDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(paymentHistoryReaderCard.fulfilled, (state, action) => {
        if (action.type === 'paymentHistory/paymentHistoryReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.paymentHistoryDetail.paymentHistoryData.prefix = action.payload.title_th
            state.paymentHistoryDetail.paymentHistoryData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.paymentHistoryDetail.paymentHistoryData.idcard = action.payload.nat_id
            state.paymentHistoryDetail.paymentHistoryData.birthday = action.payload.birthdate
            state.paymentHistoryDetail.paymentHistoryData.address = action.payload.address_no
            state.paymentHistoryDetail.paymentHistoryData.street = action.payload.address_road
            state.paymentHistoryDetail.paymentHistoryData.province = action.payload.address_provinice
            state.paymentHistoryDetail.paymentHistoryData.district = action.payload.address_amphor
            state.paymentHistoryDetail.paymentHistoryData.subdistrict = action.payload.address_tumbol
            state.paymentHistoryDetail.paymentHistoryData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(paymentHistoryPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'paymentHistory/payment/pdf/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, paymentHistoryNew, paymentHistoryAddHandle, paymentHistoryDropdownHandle, paymentHistoryDeleteHandle, paymentHistoryOnChangeHandle, paymentHistoryLoading, paymentHistoryAlert, paymentHistoryRefundDelete } = paymentHistorySlice.actions

export default paymentHistorySlice.reducer
