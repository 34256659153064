// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'

import apiHandle from '../../../configs/apiConfig'

// export const registerReaderCard = createAsyncThunk('register/registerReaderCard', async () => {
//   const response = await apiHandle('POST', 'mockreadercard')
//   return response
// })

// export const getDataTable = createAsyncThunk('register/getDataTable', async (params) => {
//   const response = await apiHandle('GET', 'application/gettable', params)
//   return response
// })

export const customerCheck = createAsyncThunk('testing/customerCheck', async (params) => {
  const response = await apiHandle('GET', 'customer/check', params)
  return response
})

export const customerExam = createAsyncThunk('testing/customerExam', async (params) => {
  const response = await apiHandle('GET', 'customer/exam', params)
  return response
})


export const testingSlice = createSlice({
  name: 'testing',
  initialState: {
    auth: null,
    sizing: 0,
    data:[]
  },
  reducers: {
    sizingHandle: (state, action) => {
      if (action.type === 'testing/sizingHandle') {
        console.log(action)
        state.sizing = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(customerCheck.fulfilled, (state, action) => {
        if (action.type === 'testing/customerCheck/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.auth = action.payload.data
          }
        }
      })
      .addCase(customerExam.fulfilled, (state, action) => {
        if (action.type === 'testing/customerExam/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.data = action.payload.data
          }
        }
      })

  }
})

export const { sizingHandle } = testingSlice.actions


export default testingSlice.reducer
