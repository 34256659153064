// ** Axios Imports
import axios from 'axios'
// ** Third Party Components

// const url = 'http://localhost:3000/api/'
const url = process.env.REACT_APP_API ? process.env.REACT_APP_API : 'https://ktytech.com/best/api/'

const tokenHandle = () => {

    const authentication = window.localStorage.getItem('userData')
    // console.log("Authentication : ", JSON.parse(authentication).access_token)
    return authentication ? JSON.parse(authentication).access_token : null
}

const refreshToken = async () => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${JSON.parse(window.localStorage.getItem('userData')).access_token}`
        },
        body: JSON.stringify({
            refreshToken: JSON.parse(window.localStorage.getItem('userData')).refresh_token
        })
    }
    const response = await fetch(`${url}user/refresh`, config)
    if (response.status >= 400) {
        // error
        window.localStorage.removeItem('userData')
        window.localStorage.removeItem('rootState')
        localStorage.setItem('needRefresh', true)
        window.location = '/login'
        return false
    } else {
        const res = await response.json()
        //success
        return true
    }

}

const apiHandle = async (method, path, params) => {
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${tokenHandle()}`
        }
    }
    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
        config = {
            method: method,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${tokenHandle()}`
            },
            body: JSON.stringify(params)
        }
        const response = await fetch(url + path, config)
        if (response.status > 400) {
            // error
            const refresh = await refreshToken()
            if (refresh) {
                return await apiHandle(method, path, params)
            }
        } else {
            const res = await response.json()
            console.log(`${method} ${path} response : `, res)
            return res
        }
    }
    if (method === 'GET') {
        if (params) {
            if (path === 'application/get' ||
                path === 'applicationfee/get' ||
                path === 'user/get' ||
                path === 'course/get' ||
                path === 'otherfee/get' ||
                path === 'promotion/get' ||
                path === 'branch/get' ||
                path === 'vehicle/get' ||
                path === 'list-payroll/get' ||
                path === 'payroll/user' ||
                path === 'list-expenses/get' ||
                path === 'income/get' ||
                path === 'expenses/get' ||
                path === 'list-expenses/get' ||
                path === 'evaluate/get'
            ) {
                path = `${path}/${params}`
            } if (path === 'payroll/userhistory') {
                path = `${path}/${params?.user_id}?month=${params?.month}`
            } else {
                path = `${path}?${new URLSearchParams(params)}`
            }
        }
        // const response = await axios.get(url + path, config)
        // const response = await fetch(url + path, config)
        let response 
        if (path === "mockreadercard") {
            response = await fetch("http://localhost:21998/readCard")
        } else {
          response = await fetch(url + path, config)
        }
        if (response.status > 400) {
            // error
            const refresh = await refreshToken()
            if (refresh) {

                return await apiHandle(method, path, params)
            }
            
            // window.localStorage.removeItem('userData')
            // window.localStorage.removeItem('rootState')
            // window.localStorage.href('rootState')
            // navigate('www.google.com')
            // history.push('/login')
        } else {
            const res = await response.json()
            console.log(`${method} ${path} response : `, res)
            return res
        }
    }

}

export default apiHandle
