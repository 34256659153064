// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../configs/apiConfig'


export const registerCreate = createAsyncThunk('register/application/create', async (params) => {
  const response = await apiHandle('POST', 'application/create', params)
  return response
})

export const registerUpdateProfile = createAsyncThunk('register/customer/updateprofile', async (params) => {
  const response = await apiHandle('PUT', 'customer/updateprofile', params)
  return response
})

export const registerPay = createAsyncThunk('register/application/paybalance', async (params) => {
  const response = await apiHandle('POST', 'application/paybalance', params)
  return response
})

export const registerRefund = createAsyncThunk('register/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const registerCheckPromotion = createAsyncThunk('register/application/checkpromotion', async (params) => {
  const response = await apiHandle('POST', 'application/checkpromotion', params)
  return response
})


export const registerDelete = createAsyncThunk('register/application/delete', async (params) => {
  const response = await apiHandle('POST', 'application/delete', params)
  return response
})


export const registerPDFPaymentCreate = createAsyncThunk('register/application/pdfpayment/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdfpayment', params)
  return response
})

export const registerPDFCreate = createAsyncThunk('register/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const registerPDFRefundCreate = createAsyncThunk('register/request/refund/pdf', async (params) => {
  const response = await apiHandle('POST', 'request/refund/pdf', params)
  return response
})


export const registerReaderCard = createAsyncThunk('register/registerReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const registerCheckExsit = createAsyncThunk('register/registerCheckExsit', async (params) => {
  const response = await apiHandle('GET', 'customer/check', params)
  return response
})

export const getDataTable = createAsyncThunk('register/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'application/gettable', params)
  return response
})

export const registerDataDetail = createAsyncThunk('register/registerDataDetail', async (params) => {
  const response = await apiHandle('GET', 'application/get', params)
  return response
})

export const registerGetCourse = createAsyncThunk('register/registerGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforregister', params)
  return response
})

export const getAllData = createAsyncThunk('register/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('register/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('register/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('register/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('register/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultCoruseCost = {
  course_id: '',
  course_name: null,
  promotion_code: null,
  exam_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultOtherCost = {
  other_fee_id: null,
  other_fee_name: null,
  promotion_code: null,
  exam_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultPayments = {
  payment_id: null,
  payment_code: null,
  pay_type: null,
  paid: null,
  createdAt: null
}


const defaultReisterDetail = {
  is_foreigner: false,
  prefix: '',
  name: '',
  fname: '',
  sname: '',
  idcard: '',
  birthday: null,
  address: null,
  moo: '',
  soi: '',
  street: '',
  subdistrict: '',
  district: '',
  province: '',
  phone_1: '',
  phone_2: '',
  age: '',
  course: [defaultCoruseCost],
  other_fee: [defaultOtherCost],
  payments: [defaultPayments],
  paid: '',
  discount: '',
  net: '',
  balance: '',
  total: '',
  // pay_type: 'เงินสด',
  status: null,
  application_code: null,
  course_id: '',
  createStatus: false,
  application_id: null,
  promotion: null,
  promotion_id: null,
  customer_id: null,
  pdf: [],
  mode: null
}


export const registerSlice = createSlice({
  name: 'register',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    registerData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    registerDetail: {
      isEdit: false,
      dropdown: {
        course: [],
        other_fee: []
      },
      userData: defaultReisterDetail
    },
    alert: {
      active: false,
      message: ''
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      balance: null,
      net: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'register/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    registerNew: (state, action) => {
      if (action.type === 'register/registerNew') {
        state.registerDetail.isEdit = false
        state.registerDetail.userData = defaultReisterDetail
      }
    },
    registerAddHandle: (state, action) => {
      if (action.type === 'register/registerAddHandle') {
        if (action.payload.target === 'course') {
          state.registerDetail.userData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.registerDetail.userData.other_fee.push(defaultOtherCost)
        }

      }
    },
    registerDeleteHandle: (state, action) => {
      if (action.type === 'register/registerDeleteHandle') {
        if (action.payload.target === 'course') {
          state.registerDetail.userData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.registerDetail.userData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    registerDropdownHandle: (state, action) => {
      if (action.type === 'register/registerDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.registerDetail.isEdit === false) {
          state.registerDetail.userData.course[action.payload.index].course_id = action.payload.value.course_id
          state.registerDetail.userData.course[action.payload.index].course_name = action.payload.value.name
          state.registerDetail.userData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.registerDetail.userData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.registerDetail.isEdit === false) {
          state.registerDetail.userData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.registerDetail.userData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.registerDetail.userData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.registerDetail.userData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    registerOnChangeHandle: (state, action) => {
      if (action.type === 'register/registerOnChangeHandle') {
        if (action.payload.target === 'paid' || action.payload.target === 'promotion_code' || action.payload.target === 'discount') {
          state.registerDetail.userData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.registerDetail.userData[action.payload.target] = action.payload.value
        }

      }
    },
    registerLoading: (state, action) => {
      if (action.type === 'register/registerLoading') {
        state.registerData.isLoading = true
      }
    },
    registerAlert: (state, action) => {
      if (action.type === 'register/registerAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    registerRefundDelete: (state, action) => {
      if (action.type === 'register/registerRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
          state.refundAndDelete.balance = null
          state.refundAndDelete.net = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(registerCheckPromotion.fulfilled, (state, action) => {
        if (action.type === 'register/application/checkpromotion/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
            state.registerDetail.userData.course[action.meta.arg.index].promotion_code = null
          } else {
            // state.alert.active = true
            // state.alert.err = false
            // state.alert.message = action.payload.message
            state.registerDetail.userData.course[action.meta.arg.index].discount = action.payload.data.discount

          }
        }
      })
      .addCase(registerUpdateProfile.fulfilled, (state, action) => {
        if (action.type === 'register/customer/updateprofile/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.alert.active = true
            state.alert.err = false
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(registerCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'register/application/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.registerDetail.userData.createStatus = true
            state.registerDetail.userData.pdf = action.payload.data
          }
        }
      })
      .addCase(registerPay.fulfilled, (state, action) => {
        if (action.type === 'register/application/paybalance/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.registerDetail.userData.createStatus = true
            state.registerDetail.userData.pdf = action.payload.data.pdf
          }
        }
      })
      .addCase(registerRefund.fulfilled, (state, action) => {
        if (action.type === 'register/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.refundAndDelete.balance = null
            state.refundAndDelete.net = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
            state.registerDetail.userData.mode = null
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.refundAndDelete.balance = null
            state.refundAndDelete.net = null
            state.alert.active = true
            state.alert.err = false
            state.alert.message = action.payload.message
            state.registerDetail.userData.mode = 'refund'
            state.registerDetail.userData.pdf = action.payload.data
          }
        }
      })
      .addCase(registerDelete.fulfilled, (state, action) => {
        if (action.type === 'register/application/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.refundAndDelete.balance = null
            state.refundAndDelete.net = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'register/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.registerData = action.payload
            state.registerDetail.userData = defaultReisterDetail
            state.registerData.isLoading = false
          }
        }
      })
      .addCase(registerDataDetail.fulfilled, (state, action) => {
        if (action.type === 'register/registerDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.registerDetail.isEdit = true
            state.registerDetail.userData.prefix = action.payload.data.customer.prefix ? action.payload.data.customer.prefix : ''
            state.registerDetail.userData.name = action.payload.data.customer.name ? action.payload.data.customer.name : ''
            state.registerDetail.userData.fname = action.payload.data.customer.fname ? action.payload.data.customer.fname : ''
            state.registerDetail.userData.sname = action.payload.data.customer.sname ? action.payload.data.customer.sname : ''
            state.registerDetail.userData.idcard = action.payload.data.customer.idcard ? action.payload.data.customer.idcard : ''
            state.registerDetail.userData.birthday = action.payload.data.customer.birthday ? new Date(action.payload.data.customer.birthday) : null
            state.registerDetail.userData.age = action.payload.data.customer.age ? action.payload.data.customer.age : ''
            state.registerDetail.userData.address = action.payload.data.customer.address ? action.payload.data.customer.address : ''
            state.registerDetail.userData.moo = action.payload.data.customer.moo ? action.payload.data.customer.moo : ''
            state.registerDetail.userData.soi = action.payload.data.customer.soi ? action.payload.data.customer.soi : ''
            state.registerDetail.userData.street = action.payload.data.customer.street ? action.payload.data.customer.street : ''
            state.registerDetail.userData.province = action.payload.data.customer.province ? action.payload.data.customer.province : ''
            state.registerDetail.userData.district = action.payload.data.customer.district ? action.payload.data.customer.district : ''
            state.registerDetail.userData.subdistrict = action.payload.data.customer.subdistrict ? action.payload.data.customer.subdistrict : ''
            state.registerDetail.userData.phone_1 = action.payload.data.customer.phone_1 ? action.payload.data.customer.phone_1 : ''
            state.registerDetail.userData.phone_2 = action.payload.data.customer.phone_2 ? action.payload.data.customer.phone_2 : ''
            state.registerDetail.userData.status = action.payload.data.status ? action.payload.data.status : null
            state.registerDetail.userData.application_code = action.payload.data.application_code ? action.payload.data.application_code : null
            state.registerDetail.userData.paid = action.payload.data.paid === 0 ? '0' : action.payload.data.paid.toString()
            state.registerDetail.userData.course_id = action.payload.data.paid ? action.payload.data.course_id.toString() : ''
            state.registerDetail.userData.discount = action.payload.data.discount === 0 ? '0' : action.payload.data.discount.toString()
            state.registerDetail.userData.net = action.payload.data.net === 0 ? '0' : action.payload.data.net.toString()
            state.registerDetail.userData.balance = action.payload.data.balance === 0 ? '0' : action.payload.data.balance.toString()
            state.registerDetail.userData.total = action.payload.data.total === 0 ? '0' : action.payload.data.total.toString()
            state.registerDetail.userData.payments = action.payload.data.payments
            state.registerDetail.userData.application_id = action.payload.data.application_id
            state.registerDetail.userData.is_foreigner = action.payload.data.customer.is_foreigner
            state.registerDetail.userData.customer_id = action.payload.data.customer.customer_id
            state.registerDetail.userData.promotion = action.payload.data.promotion
            state.registerDetail.userData.course = [
              {
                course_id: action.payload.data.course_id ? action.payload.data.course_id.toString() : '',
                course_name: null,
                promotion_code: action.payload.data.promotion ? action.payload.data.promotion.code : null,
                exam_date: null,
                cost: action.payload.data.balance ? action.payload.data.balance.toString() : '',
                discount: null,
                paid: null,
                pay_type: null
              }
            ]
          }
        }
      })
      .addCase(registerGetCourse.fulfilled, (state, action) => {
        if (action.type === 'register/registerGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.registerDetail.dropdown.course = action.payload.course
            state.registerDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(registerReaderCard.fulfilled, (state, action) => {
        if (action.type === 'register/registerReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.registerDetail.userData.prefix = action.payload.title_th
            // state.registerDetail.userData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.registerDetail.userData.fname = action.payload.fname_th
            state.registerDetail.userData.sname = action.payload.sname_th
            state.registerDetail.userData.idcard = action.payload.nat_id
            state.registerDetail.userData.birthday = action.payload.birthdate ? new Date(action.payload.birthdate) : null
            state.registerDetail.userData.address = action.payload.address_no
            state.registerDetail.userData.street = action.payload.address_road
            state.registerDetail.userData.province = action.payload.address_provinice
            state.registerDetail.userData.district = action.payload.address_amphor
            state.registerDetail.userData.subdistrict = action.payload.address_tumbol
            state.registerDetail.userData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(registerCheckExsit.fulfilled, (state, action) => {
        if (action.type === 'register/registerCheckExsit/fulfilled') {
          if (action.payload.status === 'success') {
            if (action.payload.data) {
              state.registerDetail.userData.prefix = action.payload.data.prefix ? action.payload.data.prefix : ''
              state.registerDetail.userData.name = action.payload.data.name ? action.payload.data.name : ''
              state.registerDetail.userData.fname = action.payload.data.fname ? action.payload.data.fname : ''
              state.registerDetail.userData.sname = action.payload.data.sname ? action.payload.data.sname : ''
              state.registerDetail.userData.idcard = action.payload.data.idcard ? action.payload.data.idcard : ''
              state.registerDetail.userData.birthday = action.payload.data.birthday ? action.payload.data.birthday : null
              state.registerDetail.userData.address = action.payload.data.address ? action.payload.data.address : ''
              state.registerDetail.userData.moo = action.payload.data.moo ? action.payload.data.moo : ''
              state.registerDetail.userData.soi = action.payload.data.soi ? action.payload.data.soi : ''
              state.registerDetail.userData.street = action.payload.data.street ? action.payload.data.street : ''
              state.registerDetail.userData.province = action.payload.data.province ? action.payload.data.province : ''
              state.registerDetail.userData.district = action.payload.data.district ? action.payload.data.district : ''
              state.registerDetail.userData.subdistrict = action.payload.data.subdistrict ? action.payload.data.subdistrict : ''
              state.registerDetail.userData.phone_1 = action.payload.data.phone_1 ? action.payload.data.phone_1 : ''
              state.registerDetail.userData.phone_2 = action.payload.data.phone_2 ? action.payload.data.phone_2 : ''
            } else {

            }

          } else {
            //handleError
          }
        }
      })
      .addCase(registerPDFCreate.fulfilled, (state, action) => {
        if (action.type === 'register/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(registerPDFRefundCreate.fulfilled, (state, action) => {
        if (action.type === 'register/request/refund/pdf/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(registerPDFPaymentCreate.fulfilled, (state, action) => {
        if (action.type === 'register/application/pdfpayment/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, registerNew, registerAddHandle, registerDropdownHandle, registerDeleteHandle, registerOnChangeHandle, registerLoading, registerAlert, registerRefundDelete } = registerSlice.actions

export default registerSlice.reducer
