// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../../configs/apiConfig'


export const manageOtherCreate = createAsyncThunk('manageOther/application/create', async (params) => {
  const response = await apiHandle('POST', 'otherfee/create', params)
  return response
})


export const manageOtherUpdate = createAsyncThunk('manageOther/otherfee/update', async (params) => {
  const response = await apiHandle('PUT', 'otherfee/update', params)
  return response
}) 

export const manageOtherRefund = createAsyncThunk('manageOther/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const manageOtherDelete = createAsyncThunk('manageOther/otherfee/delete', async (params) => {
  const response = await apiHandle('DELETE', 'otherfee/delete', params)
  return response
})


export const manageOtherPDFCreate = createAsyncThunk('manageOther/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const manageOtherReaderCard = createAsyncThunk('manageOther/manageOtherReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const getDataTable = createAsyncThunk('manageOther/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'otherfee/gettable', params)
  return response
})

export const manageOtherDataDetail = createAsyncThunk('manageOther/manageOtherDataDetail', async (params) => {
  const response = await apiHandle('GET', 'otherfee/get', params)
  return response
})

export const manageOtherGetCourse = createAsyncThunk('manageOther/manageOtherGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getformanageOther', params)
  return response
})

export const getAllData = createAsyncThunk('manageOther/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('manageOther/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('manageOther/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('manageOther/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('manageOther/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultOtherDetail = {
  other_fee_id: '',
  branch_id: '',
  createdAt: '',
  detail: '',
  is_activate: '',
  is_delete: '',
  name: '',
  price: '',
  updatedAt: '',
  createStatus: false,
  application_id: null
}


export const manageOtherSlice = createSlice({
  name: 'manageOther',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    manageOtherData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    manageOtherDetail: {
      isEdit: false,
      dropdown: {
        status: [
          { label: 'เปิดใช้งาน', value: true },
          { label: 'ปิดใช้งาน', value: false }
        ]
      },
      otherData: defaultOtherDetail
    },
    alert: {
      active: false,
      message: '',
      err: false
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'manageOther/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    manageOtherNew: (state, action) => {
      if (action.type === 'manageOther/manageOtherNew') {
        state.manageOtherDetail.isEdit = false
        state.manageOtherDetail.otherData = defaultOtherDetail
      }
    },
    manageOtherAddHandle: (state, action) => {
      if (action.type === 'manageOther/manageOtherAddHandle') {
        if (action.payload.target === 'course') {
          state.manageOtherDetail.otherData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.manageOtherDetail.otherData.other_fee.push(defaultOtherCost)
        }

      }
    },
    manageOtherDeleteHandle: (state, action) => {
      if (action.type === 'manageOther/manageOtherDeleteHandle') {
        if (action.payload.target === 'course') {
          state.manageOtherDetail.otherData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.manageOtherDetail.otherData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    manageOtherDropdownHandle: (state, action) => {
      if (action.type === 'manageOther/manageOtherDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.manageOtherDetail.isEdit === false) {
          state.manageOtherDetail.otherData.course[action.payload.index].course_id = action.payload.value.course_id
          state.manageOtherDetail.otherData.course[action.payload.index].course_name = action.payload.value.name
          state.manageOtherDetail.otherData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.manageOtherDetail.otherData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.manageOtherDetail.isEdit === false) {
          state.manageOtherDetail.otherData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.manageOtherDetail.otherData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.manageOtherDetail.otherData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.manageOtherDetail.otherData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    manageOtherOnChangeHandle: (state, action) => {
      if (action.type === 'manageOther/manageOtherOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.manageOtherDetail.otherData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.manageOtherDetail.otherData[action.payload.target] = action.payload.value
        }

      }
    },
    manageOtherLoading: (state, action) => {
      if (action.type === 'manageOther/manageOtherLoading') {
        state.manageOtherData.isLoading = true
      }
    },
    manageOtherAlert: (state, action) => {
      if (action.type === 'manageOther/manageOtherAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageOtherRefundDelete: (state, action) => {
      if (action.type === 'manageOther/manageOtherRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(manageOtherCreate.fulfilled, (state, action) => {
        if (action.type === 'manageOther/application/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(manageOtherUpdate.fulfilled, (state, action) => {
        if (action.type === 'manageOther/otherfee/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(manageOtherRefund.fulfilled, (state, action) => {
        if (action.type === 'manageOther/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(manageOtherDelete.fulfilled, (state, action) => {
        if (action.type === 'manageOther/otherfee/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'manageOther/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageOtherData = action.payload
            state.manageOtherData.isLoading = false
          }
        }
      })
      .addCase(manageOtherDataDetail.fulfilled, (state, action) => {
        if (action.type === 'manageOther/manageOtherDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageOtherDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              if (data === null) data = ''
              state.manageOtherDetail.otherData[i] = data
            }
          }
        }
      })
      .addCase(manageOtherGetCourse.fulfilled, (state, action) => {
        if (action.type === 'manageOther/manageOtherGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageOtherDetail.dropdown.course = action.payload.course
            state.manageOtherDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(manageOtherReaderCard.fulfilled, (state, action) => {
        if (action.type === 'manageOther/manageOtherReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.manageOtherDetail.otherData.prefix = action.payload.title_th
            state.manageOtherDetail.otherData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.manageOtherDetail.otherData.idcard = action.payload.nat_id
            state.manageOtherDetail.otherData.birthday = action.payload.birthdate
            state.manageOtherDetail.otherData.address = action.payload.address_no
            state.manageOtherDetail.otherData.street = action.payload.address_road
            state.manageOtherDetail.otherData.province = action.payload.address_provinice
            state.manageOtherDetail.otherData.district = action.payload.address_amphor
            state.manageOtherDetail.otherData.subdistrict = action.payload.address_tumbol
            state.manageOtherDetail.otherData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(manageOtherPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'manageOther/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, manageOtherNew, manageOtherAddHandle, manageOtherDropdownHandle, manageOtherDeleteHandle, manageOtherOnChangeHandle, manageOtherLoading, manageOtherAlert, manageOtherRefundDelete } = manageOtherSlice.actions

export default manageOtherSlice.reducer
