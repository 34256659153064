// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
// import calendar from '@src/views/apps/calendar/store'
import calendar from '@src/views/work/dueTest/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import register from '@src/views/work/register/store'
import request from '@src/views/work/request/store'
import testing from '@src/views/testing/store'
import other from '@src/views/work/other/store'
import overdue from '@src/views/work/overdue/store'
import history from '@src/views/work/testing/history/store'
import income from '@src/views/account/income-expense/income/store'
import expenses from '@src/views/account/income-expense/expenses/store'
import exam from '@src/views/work/dueTest/exam/store'
import permission from '@src/views/work/testing/permission/store'
import manageCourse from '@src/views/manage/course/store'
import manageExam from '@src/views/manage/exam/store'
import manageDiscount from '@src/views/manage/type-discount/discount/store'
import managePayType from '@src/views/manage/type-discount/payType/store'
import manageOther from '@src/views/manage/type-discount/other/store'
import manageVehicleCheck from '@src/views/manage/type-discount/vehicleCheck/store' 
import manageExpenses from '@src/views/manage/type-discount/expenses/store'
import manageBranch from '@src/views/manage/branch/store'
import manageEmployee from '@src/views/manage/employee/store'
import manageVehicle from '@src/views/manage/vehicle/store'
import paymentHistory from '@src/views/account/payment-history/store'
import outstandingBalance from '@src/views/account/outstanding-balance/store'
import payrollManage from '@src/views/payroll/manage/store'
import payrollListUser  from '@src/views/payroll/component/Modal/store'
import payrollList  from '@src/views/payroll/list/store'
import payrollReport  from '@src/views/payroll/report/store'
import account  from '@src/views/account/income-expense/store'
import evaluateManage  from '@src/views/evaluate/manage/store'
import manageVehicleDetail from '@src/views/manage/vehicle/detail/store'
const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  register,
  testing,
  other,
  overdue,
  history,
  permission,
  request,
  exam,
  income,
  expenses,
  manageCourse,
  manageExam,
  manageDiscount,
  managePayType,
  manageOther,
  manageVehicleCheck,
  manageBranch,
  manageEmployee,
  manageVehicle,
  paymentHistory,
  payrollManage,
  payrollListUser,
  payrollList,
  manageExpenses,
  account,
  payrollReport,
  evaluateManage,
  evaluateManage,
  manageVehicleDetail,
  outstandingBalance
}

export default rootReducer
