// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'

import apiHandle from '../../../../../configs/apiConfig'

export const getDataTable = createAsyncThunk('exam/gettable', async (params) => {
  const response = await apiHandle('GET', 'exam/gettable', params)
  return response
})

export const examChangeDate = createAsyncThunk('exam/changedate', async (params) => {
  const response = await apiHandle('POST', 'exam/changedate', params)
  return response
})


// const defaultCoruseCost = {
//   course_id: '',
//   course_name: null,
//   promotion_code: null,
//   exam_date: null,
//   cost: null,
//   discount: null,
//   paid: null,
//   pay_type: null
// }

// const defaultOtherCost = {
//   exam_fee_id: null,
//   exam_fee_name: null,
//   promotion_code: null,
//   exam_date: null,
//   cost: null,
//   discount: null,
//   paid: null,
//   pay_type: null
// }

// const defaultPayments = {
//   payment_id: null,
//   payment_code: null,
//   pay_type: null,
//   paid: null,
//   createdAt: null
// }


// const defaultReisterDetail = {
//   is_foreigner: false,
//   prefix: '',
//   name: '',
//   idcard: '',
//   birthday: null,
//   address: null,
//   moo: '',
//   soi: '',
//   street: '',
//   subdistrict: '',
//   district: '',
//   province: '',
//   phone_1: '',
//   phone_2: '',
//   age: '',
//   course: [defaultCoruseCost],
//   exam_fee: [defaultOtherCost],
//   payments: [defaultPayments],
//   paid: '',
//   discount: '',
//   net: '',
//   balance: '',
//   total: '',
//   // pay_type: 'เงินสด',
//   status: null,
//   application_code: null,
//   exam_fee_id: '',
//   createStatus: false,
//   application_id: null,
//   application_exam_fee_code:null
// }


export const examSlice = createSlice({
  name: 'exam',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedData: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      date: new Date(),
      text: ''
    },
    examData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    examDetail: [],
    alert: {
      active: false,
      message: '',
      err:false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'exam/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    examLoading: (state, action) => {
      if (action.type === 'exam/examLoading') {
        state.examData.isLoading = true
      }
    },
    examDetail: (state, action) => {
      if (action.type === 'exam/examDetail') {
        state.examDetail = action.payload
      }
    },
    examAlert: (state, action) => {
      if (action.type === 'exam/examAlert') {
        state.alert.active = false
        state.alert.message = ''
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'exam/gettable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.examData = action.payload
            state.examData.isLoading = false
          }
        }
      })
      .addCase(examChangeDate.fulfilled, (state, action) => {
      
        if (action.type === 'exam/changedate/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            console.log(action)
            state.alert.active = true
            state.alert.err = false
            state.alert.message = action.payload.message
          }
        }
      })
  }
})

export const { searchCriteria, examNew, examLoading, examDetail, examAlert } = examSlice.actions

export default examSlice.reducer
