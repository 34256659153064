// ** Redux Imports
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'

const serializedState = localStorage.getItem('rootState')
// console.log('loadState : ', JSON.parse(serializedState))
const loadState = () => {
  try {
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    const check = JSON.parse(serializedState).auth
    console.log(check)
    if (check.userData !== null) {
      localStorage.setItem('rootState', serializedState)
    } else {
      // localStorage.removeItem('rootState')
      // localStorage.clear()
    }
  } catch {
    // ignore write errors
  }
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  },
  preloadedState: loadState()
})

store.subscribe(() => {
  let check = store.getState()
  if (check.userData !== null) {
    saveState(store.getState())
  } else {
    // localStorage.removeItem('rootState')
    // localStorage.clear()
  }
})


export { store }