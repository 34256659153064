// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../configs/apiConfig'


export const manageBranchCreate = createAsyncThunk('manageBranch/branch/create', async (params) => {
  const response = await apiHandle('POST', 'branch/create', params)
  return response
})

export const manageBranchUpdate = createAsyncThunk('manageBranch/branch/update', async (params) => {
  const response = await apiHandle('PUT', 'branch/update', params)
  return response
})

export const manageBranchRefund = createAsyncThunk('manageBranch/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const manageBranchDelete = createAsyncThunk('manageBranch/application/delete', async (params) => {
  const response = await apiHandle('POST', 'application/delete', params)
  return response
})


export const manageBranchPDFCreate = createAsyncThunk('manageBranch/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const manageBranchReaderCard = createAsyncThunk('manageBranch/manageBranchReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const getDataTable = createAsyncThunk('manageBranch/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'branch/get', params)
  return response
})

export const manageBranchDataDetail = createAsyncThunk('manageBranch/manageBranchDataDetail', async (params) => {
  const response = await apiHandle('GET', 'branch/get', params)
  return response
})

export const manageBranchGetCourse = createAsyncThunk('manageBranch/manageBranchGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getformanageBranch', params)
  return response
})

export const getAllData = createAsyncThunk('manageBranch/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('manageBranch/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('manageBranch/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('manageBranch/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('manageBranch/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})


const defaultBranchDetail = {
  address: '',
  branch_id: '',
  createdAt: '',
  district: '',
  is_delete: '',
  line: '',
  moo: '',
  name: '',
  parent_id: '',
  phone_1: '',
  phone_2: '',
  postcode: '',
  province: '',
  soi: '',
  street: '',
  subdistrict: '',
  updatedAt: '',
  createStatus: false
}


export const manageBranchSlice = createSlice({
  name: 'manageBranch',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ค้างชำระ', value: 'ค้างชำระ' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    manageBranchData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    manageBranchDetail: {
      isEdit: false,
      dropdown: {
        course: [],
        other_fee: []
      },
      branchData: defaultBranchDetail
    },
    alert: {
      active: false,
      message: '',
      err: false
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'manageBranch/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    manageBranchNew: (state, action) => {
      if (action.type === 'manageBranch/manageBranchNew') {
        state.manageBranchDetail.isEdit = false
        state.manageBranchDetail.branchData = defaultBranchDetail
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageBranchAddHandle: (state, action) => {
      if (action.type === 'manageBranch/manageBranchAddHandle') {
        if (action.payload.target === 'course') {
          state.manageBranchDetail.userData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.manageBranchDetail.userData.other_fee.push(defaultOtherCost)
        }

      }
    },
    manageBranchDeleteHandle: (state, action) => {
      if (action.type === 'manageBranch/manageBranchDeleteHandle') {
        if (action.payload.target === 'course') {
          state.manageBranchDetail.userData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.manageBranchDetail.userData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    manageBranchDropdownHandle: (state, action) => {
      if (action.type === 'manageBranch/manageBranchDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.manageBranchDetail.isEdit === false) {
          state.manageBranchDetail.userData.course[action.payload.index].course_id = action.payload.value.course_id
          state.manageBranchDetail.userData.course[action.payload.index].course_name = action.payload.value.name
          state.manageBranchDetail.userData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.manageBranchDetail.userData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.manageBranchDetail.isEdit === false) {
          state.manageBranchDetail.userData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.manageBranchDetail.userData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.manageBranchDetail.userData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.manageBranchDetail.userData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    manageBranchOnChangeHandle: (state, action) => {
      if (action.type === 'manageBranch/manageBranchOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.manageBranchDetail.userData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.manageBranchDetail.branchData[action.payload.target] = action.payload.value
        }

      }
    },
    manageBranchLoading: (state, action) => {
      if (action.type === 'manageBranch/manageBranchLoading') {
        state.manageBranchData.isLoading = true
      }
    },
    manageBranchAlert: (state, action) => {
      if (action.type === 'manageBranch/manageBranchAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageBranchRefundDelete: (state, action) => {
      if (action.type === 'manageBranch/manageBranchRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(manageBranchCreate.fulfilled, (state, action) => {
        if (action.type === 'manageBranch/branch/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.manageCourseDetail.courseData.createStatus = true
            // state.manageCourseDetail.courseData.pdf = action.payload.data
          }
        }
      })
      .addCase(manageBranchUpdate.fulfilled, (state, action) => {
        if (action.type === 'manageBranch/branch/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.manageCourseDetail.courseData.createStatus = true
            // state.manageCourseDetail.courseData.pdf = action.payload.data
          }
        }
      })
      .addCase(manageBranchRefund.fulfilled, (state, action) => {
        if (action.type === 'manageBranch/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(manageBranchDelete.fulfilled, (state, action) => {
        if (action.type === 'manageBranch/application/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'manageBranch/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            console.log('action', action)
            state.manageBranchData = action.payload
            state.manageBranchData.isLoading = false
          }
        }
      })
      .addCase(manageBranchDataDetail.fulfilled, (state, action) => {
        if (action.type === 'manageBranch/manageBranchDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            console.log(action)
            state.manageBranchDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              if (data === null) data = ''
              state.manageBranchDetail.branchData[i] = data
            }
          }
        }
      })
      .addCase(manageBranchGetCourse.fulfilled, (state, action) => {
        if (action.type === 'manageBranch/manageBranchGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageBranchDetail.dropdown.course = action.payload.course
            state.manageBranchDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(manageBranchReaderCard.fulfilled, (state, action) => {
        if (action.type === 'manageBranch/manageBranchReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.manageBranchDetail.userData.prefix = action.payload.title_th
            state.manageBranchDetail.userData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.manageBranchDetail.userData.idcard = action.payload.nat_id
            state.manageBranchDetail.userData.birthday = action.payload.birthdate
            state.manageBranchDetail.userData.address = action.payload.address_no
            state.manageBranchDetail.userData.street = action.payload.address_road
            state.manageBranchDetail.userData.province = action.payload.address_provinice
            state.manageBranchDetail.userData.district = action.payload.address_amphor
            state.manageBranchDetail.userData.subdistrict = action.payload.address_tumbol
            state.manageBranchDetail.userData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(manageBranchPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'manageBranch/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, manageBranchNew, manageBranchAddHandle, manageBranchDropdownHandle, manageBranchDeleteHandle, manageBranchOnChangeHandle, manageBranchLoading, manageBranchAlert, manageBranchRefundDelete } = manageBranchSlice.actions

export default manageBranchSlice.reducer
