// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../configs/apiConfig'


export const manageCourseCreate = createAsyncThunk('manageCourse/course/create', async (params) => {
  const response = await apiHandle('POST', 'course/create', params)
  return response
}) 

export const manageCourseUpdate = createAsyncThunk('manageCourse/course/update', async (params) => {
  const response = await apiHandle('PUT', 'course/update', params)
  return response
}) 

export const manageCourseRefund = createAsyncThunk('manageCourse/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const manageCourseDelete = createAsyncThunk('manageCourse/course/delete', async (params) => {
  const response = await apiHandle('DELETE', 'course/delete', params)
  return response
})


export const manageCoursePDFCreate = createAsyncThunk('manageCourse/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const manageCourseReaderCard = createAsyncThunk('manageCourse/manageCourseReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const manageCourseGetList = createAsyncThunk('manageCourse/course/type', async (params) => {
  const response = await apiHandle('GET', 'course/type', params)
  return response
})

export const getDataTable = createAsyncThunk('manageCourse/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'course/gettable', params)
  return response
})

export const manageCourseDataDetail = createAsyncThunk('manageCourse/manageCourseDataDetail', async (params) => {
  const response = await apiHandle('GET', 'course/get', params)
  return response
})

export const manageCourseGetCourse = createAsyncThunk('manageCourse/manageCourseGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getformanageCourse', params)
  return response
})

export const getAllData = createAsyncThunk('manageCourse/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('manageCourse/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('manageCourse/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('manageCourse/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('manageCourse/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})


const defaultCourseDetail = {
  course_id: '',
  branch_id: '',
  createdAt: '',
  detail: '',
  is_activate: '',
  is_delete: '',
  name: '',
  price: '',
  type: '',
  updatedAt: '',
  createStatus: false
}


export const manageCourseSlice = createSlice({
  name: 'manageCourse',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    manageCourseData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    manageCourseDetail: {
      isEdit: false,
      dropdown: {
        type: [],
        status: [
          { label: 'เปิดใช้งาน', value: true },
          { label: 'ปิดใช้งาน', value: false }
        ]
      },
      courseData: defaultCourseDetail
    },
    alert: {
      active: false,
      message: ''
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'manageCourse/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    manageCourseNew: (state, action) => {
      if (action.type === 'manageCourse/manageCourseNew') {
        state.manageCourseDetail.isEdit = false
        state.manageCourseDetail.courseData = defaultCourseDetail
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageCourseAddHandle: (state, action) => {
      if (action.type === 'manageCourse/manageCourseAddHandle') {
        if (action.payload.target === 'course') {
          state.manageCourseDetail.courseData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.manageCourseDetail.courseData.other_fee.push(defaultOtherCost)
        }

      }
    },
    manageCourseDeleteHandle: (state, action) => {
      if (action.type === 'manageCourse/manageCourseDeleteHandle') {
        if (action.payload.target === 'course') {
          state.manageCourseDetail.courseData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.manageCourseDetail.courseData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    manageCourseDropdownHandle: (state, action) => {
      if (action.type === 'manageCourse/manageCourseDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.manageCourseDetail.isEdit === false) {
          state.manageCourseDetail.courseData.course[action.payload.index].course_id = action.payload.value.course_id
          state.manageCourseDetail.courseData.course[action.payload.index].course_name = action.payload.value.name
          state.manageCourseDetail.courseData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.manageCourseDetail.courseData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.manageCourseDetail.isEdit === false) {
          state.manageCourseDetail.courseData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.manageCourseDetail.courseData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.manageCourseDetail.courseData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.manageCourseDetail.courseData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    manageCourseOnChangeHandle: (state, action) => {
      if (action.type === 'manageCourse/manageCourseOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.manageCourseDetail.courseData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.manageCourseDetail.courseData[action.payload.target] = action.payload.value
        }

      }
    },
    manageCourseLoading: (state, action) => {
      if (action.type === 'manageCourse/manageCourseLoading') {
        state.manageCourseData.isLoading = true
      }
    },
    manageCourseAlert: (state, action) => {
      if (action.type === 'manageCourse/manageCourseAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageCourseRefundDelete: (state, action) => {
      if (action.type === 'manageCourse/manageCourseRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(manageCourseCreate.fulfilled, (state, action) => {
        if (action.type === 'manageCourse/course/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.manageCourseDetail.courseData.createStatus = true
            // state.manageCourseDetail.courseData.pdf = action.payload.data
          }
        }
      })
      .addCase(manageCourseUpdate.fulfilled, (state, action) => {
        if (action.type === 'manageCourse/course/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.manageCourseDetail.courseData.createStatus = true
            // state.manageCourseDetail.courseData.pdf = action.payload.data
          }
        }
      })
      .addCase(manageCourseRefund.fulfilled, (state, action) => {
        if (action.type === 'manageCourse/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(manageCourseDelete.fulfilled, (state, action) => {
        if (action.type === 'manageCourse/course/delete/fulfilled') {
          if (action.payload.status === 'error') {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(manageCourseGetList.fulfilled, (state, action) => {
        if (action.type === 'manageCourse/course/type/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            let arr = []
            if (Array.isArray(action.payload.data)) {
              for (const i of action.payload.data) {
                arr.push({ value: i, label: i })
              }
            }
            state.manageCourseDetail.dropdown.type = arr
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'manageCourse/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageCourseData = action.payload
            state.manageCourseData.isLoading = false
          }
        }
      })
      .addCase(manageCourseDataDetail.fulfilled, (state, action) => {
        if (action.type === 'manageCourse/manageCourseDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageCourseDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              if (data === null) data = ''
              state.manageCourseDetail.courseData[i] = data
            }
          }
        }
      })
      .addCase(manageCourseGetCourse.fulfilled, (state, action) => {
        if (action.type === 'manageCourse/manageCourseGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageCourseDetail.dropdown.course = action.payload.course
            state.manageCourseDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(manageCourseReaderCard.fulfilled, (state, action) => {
        if (action.type === 'manageCourse/manageCourseReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.manageCourseDetail.courseData.prefix = action.payload.title_th
            state.manageCourseDetail.courseData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.manageCourseDetail.courseData.idcard = action.payload.nat_id
            state.manageCourseDetail.courseData.birthday = action.payload.birthdate
            state.manageCourseDetail.courseData.address = action.payload.address_no
            state.manageCourseDetail.courseData.street = action.payload.address_road
            state.manageCourseDetail.courseData.province = action.payload.address_provinice
            state.manageCourseDetail.courseData.district = action.payload.address_amphor
            state.manageCourseDetail.courseData.subdistrict = action.payload.address_tumbol
            state.manageCourseDetail.courseData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(manageCoursePDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'manageCourse/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, manageCourseNew, manageCourseAddHandle, manageCourseDropdownHandle, manageCourseDeleteHandle, manageCourseOnChangeHandle, manageCourseLoading, manageCourseAlert, manageCourseRefundDelete } = manageCourseSlice.actions

export default manageCourseSlice.reducer
