// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


import apiHandle from '../../../../../configs/apiConfig'

export const getDataTable = createAsyncThunk('history/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'exam/gettable', params)
  return response
})


export const historySlice = createSlice({
  name: 'history',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    historyData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading:false
    },
    historyDetail: {},
    alert:{
      active:false,
      message:''
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'history/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    
    historyLoading: (state, action) => {
      if (action.type === 'history/historyLoading') {
        state.historyData.isLoading = true
      }
    },
    historyDetail: (state, action) => {
      if (action.type === 'history/historyDetail') {
        state.historyDetail = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'history/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.historyData = action.payload
            state.historyData.isLoading = false
          }
        }
      })
     
  }
})

export const { searchCriteria, historyNew, historyLoading, historyDetail } = historySlice.actions

export default historySlice.reducer
