// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../configs/apiConfig'


export const payrollListCreate = createAsyncThunk('payrollList/payroll/submit', async (params) => {
  const response = await apiHandle('POST', 'payroll/submit', params)
  return response
})

export const payrollListUpdate = createAsyncThunk('payrollList/list-payrollList/update', async (params) => {
  const response = await apiHandle('PUT', 'list-payrollList/update', params)
  return response
})

export const payrollListRefund = createAsyncThunk('payrollList/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const payrollListDelete = createAsyncThunk('payrollList/application/delete', async (params) => {
  const response = await apiHandle('POST', 'application/delete', params)
  return response
})


export const payrollListPDFCreate = createAsyncThunk('payrollList/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const payrollListReaderCard = createAsyncThunk('payrollList/payrollListReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const payrollListGetList = createAsyncThunk('payrollList/list-payrollList/list', async (params) => {
  const response = await apiHandle('GET', 'list-payrollList/list', params)
  return response
})

export const getDataTable = createAsyncThunk('payroll/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'payroll/gettable', params)
  return response
})

export const payrollListDataDetail = createAsyncThunk('payrollList/payrollListDataDetail', async (params) => {
  const response = await apiHandle('GET', 'payroll/user', params)
  return response
})

export const payrollListGetCourse = createAsyncThunk('payrollList/payrollListGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getforpayrollList', params)
  return response
})

export const payrollConfirm = createAsyncThunk('payroll/confirm', async (params) => {
  const response = await apiHandle('POST', 'payroll/confirm', params)
  return response
})

export const payrollApprove = createAsyncThunk('payroll/approve', async (params) => {
  const response = await apiHandle('POST', 'payroll/approve', params)
  return response
})

export const getAllData = createAsyncThunk('payrollList/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('payrollList/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('payrollList/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('payrollList/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('payrollList/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultpayrollListDetail = {
  deduction: [],
  income: [],
  salary: [],
  user_info: null,
  is_approve: null,
  is_payroll: null,
  createStatus: false
}


export const payrollListSlice = createSlice({
  name: 'payrollList',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ค้างชำระ', value: 'ค้างชำระ' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    payrollListData: {
      data: [],
      filter: {
        branch: [],
        position: []
      },
      meta: {},
      isLoading: false
    },
    payrollListDetail: {
      isEdit: false,
      isView: false,
      id: null,
      dropdown: {
        branch: [],
        position: []
      },
      payrollListData: defaultpayrollListDetail,
      defaultListData: defaultpayrollListDetail
    },
    alert: {
      active: false,
      message: '',
      err: false
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'payrollList/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    payrollListNew: (state, action) => {
      if (action.type === 'payrollList/payrollListNew') {
        state.payrollListDetail.isEdit = false
        state.payrollListDetail.isView = false
        state.payrollListDetail.payrollListData = defaultpayrollListDetail
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    payrollListAddHandle: (state, action) => {
      if (action.type === 'payrollList/payrollListAddHandle') {
        if (action.payload.target === 'course') {
          state.payrollListDetail.payrollListData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.payrollListDetail.payrollListData.other_fee.push(defaultOtherCost)
        }

      }
    },
    payrollListDeleteHandle: (state, action) => {
      if (action.type === 'payrollList/payrollListDeleteHandle') {
        if (action.payload.target === 'course') {
          state.payrollListDetail.payrollListData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.payrollListDetail.payrollListData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    payrollListDropdownHandle: (state, action) => {
      if (action.type === 'payrollList/payrollListDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.payrollListDetail.isEdit === false) {
          state.payrollListDetail.payrollListData.course[action.payload.index].course_id = action.payload.value.course_id
          state.payrollListDetail.payrollListData.course[action.payload.index].course_name = action.payload.value.name
          state.payrollListDetail.payrollListData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.payrollListDetail.payrollListData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.payrollListDetail.isEdit === false) {
          state.payrollListDetail.payrollListData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.payrollListDetail.payrollListData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.payrollListDetail.payrollListData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.payrollListDetail.payrollListData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    payrollListGetData: (state, action) => {
      if (action.type === 'payrollList/payrollListGetData') {
        state.payrollListDetail.payrollListData = action.payload
      }
    },
    payrollListOnChangeHandle: (state, action) => {
      if (action.type === 'payrollList/payrollListOnChangeHandle') {
        if (action.payload.target === 'per_value') {
          state.payrollListDetail.payrollListData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } if (action.payload.target === 'id') {
          state.payrollListDetail.id = action.payload.value
        } if (action.payload.target === 'isDefault') {
          state.payrollListDetail.isDefault = action.payload.value
        } if (action.payload.target === 'isEdit') {
          state.payrollListDetail.isEdit = action.payload.value
        } else {
          state.payrollListDetail.payrollListData[action.payload.target] = action.payload.value
        }

      }
    },
    payrollListLoading: (state, action) => {
      if (action.type === 'payrollList/payrollListLoading') {
        state.payrollListData.isLoading = true
      }
    },
    payrollListAlert: (state, action) => {
      if (action.type === 'payrollList/payrollListAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    payrollListRefundDelete: (state, action) => {
      if (action.type === 'payrollList/payrollListRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    },
    payrollListEdit: (state, action) => {
      if (action.type === 'payrollList/payrollListEdit') {
        state.payrollListDetail.isEdit = false
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(payrollApprove.fulfilled, (state, action) => {
        if (action.type === 'payroll/approve/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.payrollListDetail.payrollListData.createStatus = true
            // state.payrollListDetail.payrollListData.pdf = action.payload.data
          }
        }
      })
      .addCase(payrollConfirm.fulfilled, (state, action) => {
        if (action.type === 'payroll/confirm/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.payrollListDetail.payrollListData.createStatus = true
            // state.payrollListDetail.payrollListData.pdf = action.payload.data
          }
        }
      })
      .addCase(payrollListCreate.fulfilled, (state, action) => {
        if (action.type === 'payrollList/payroll/submit/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.payrollListDetail.payrollListData.createStatus = true
            // state.payrollListDetail.payrollListData.pdf = action.payload.data
          }
        }
      })
      .addCase(payrollListUpdate.fulfilled, (state, action) => {
        if (action.type === 'payrollList/list-payrollList/update/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
            // state.payrollListDetail.payrollListData.createStatus = true
            // state.payrollListDetail.payrollListData.pdf = action.payload.data
          }
        }
      })
      .addCase(payrollListRefund.fulfilled, (state, action) => {
        if (action.type === 'payrollList/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(payrollListDelete.fulfilled, (state, action) => {
        if (action.type === 'payrollList/application/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(payrollListGetList.fulfilled, (state, action) => {
        if (action.type === 'payrollList/list-payrollList/list/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            let branch = []
            let category_deduction = []
            let category_income = []
            let condition = []
            let status = []
            let tital_value = []
            let type = []
            if (action.payload.data && Array.isArray(action.payload.data.branch)) {
              for (const i of action.payload.data.branch) {
                branch.push({ value: i.value, label: i.name })
              }
            }
            state.payrollListDetail.dropdown.branch = branch

            if (action.payload.data && Array.isArray(action.payload.data.category_deduction)) {
              for (const i of action.payload.data.category_deduction) {
                category_deduction.push({ value: i, label: i })
              }
            }
            state.payrollListDetail.dropdown.category_deduction = category_deduction

            if (action.payload.data && Array.isArray(action.payload.data.category_income)) {
              for (const i of action.payload.data.category_income) {
                category_income.push({ value: i, label: i })
              }
            }
            state.payrollListDetail.dropdown.category_income = category_income

            if (action.payload.data && Array.isArray(action.payload.data.condition)) {
              for (const i of action.payload.data.condition) {
                let label = 'คูณ'
                if (i === 'fill') {
                  label = 'บวก'
                }
                condition.push({ value: i, label: label })
              }
            }
            state.payrollListDetail.dropdown.condition = condition

            if (action.payload.data && Array.isArray(action.payload.data.status)) {
              for (const i of action.payload.data.status) {
                status.push({ value: i, label: i })
              }
            }
            state.payrollListDetail.dropdown.status = status

            if (action.payload.data && Array.isArray(action.payload.data.tital_value)) {
              for (const i of action.payload.data.tital_value) {
                tital_value.push({ value: i, label: i })
              }
            }
            state.payrollListDetail.dropdown.tital_value = tital_value

            if (action.payload.data && Array.isArray(action.payload.data.type)) {
              for (const i of action.payload.data.type) {
                type.push({ value: i, label: i })
              }
            }
            state.payrollListDetail.dropdown.type = type
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'payroll/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.payrollListData = action.payload
            state.payrollListData.isLoading = false
          }
        }
      })
      .addCase(payrollListDataDetail.fulfilled, (state, action) => {
        if (action.type === 'payrollList/payrollListDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            //handleError
          } else {
            state.payrollListDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              state.payrollListDetail.payrollListData[i] = data
              state.payrollListDetail.defaultListData[i] = data
            }
            state.payrollListDetail.payrollListData.is_approve = action.payload.detail.is_approve
            state.payrollListDetail.payrollListData.is_payroll = action.payload.detail.is_payroll
          }
        }
      })
      .addCase(payrollListGetCourse.fulfilled, (state, action) => {
        if (action.type === 'payrollList/payrollListGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.payrollListDetail.dropdown.course = action.payload.course
            state.payrollListDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(payrollListReaderCard.fulfilled, (state, action) => {
        if (action.type === 'payrollList/payrollListReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.payrollListDetail.payrollListData.prefix = action.payload.title_th
            state.payrollListDetail.payrollListData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.payrollListDetail.payrollListData.idcard = action.payload.nat_id
            state.payrollListDetail.payrollListData.birthday = action.payload.birthdate
            state.payrollListDetail.payrollListData.address = action.payload.address_no
            state.payrollListDetail.payrollListData.street = action.payload.address_road
            state.payrollListDetail.payrollListData.province = action.payload.address_provinice
            state.payrollListDetail.payrollListData.district = action.payload.address_amphor
            state.payrollListDetail.payrollListData.subdistrict = action.payload.address_tumbol
            state.payrollListDetail.payrollListData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(payrollListPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'payrollList/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})
export const { searchCriteria, payrollListNew, payrollListAddHandle, payrollListDropdownHandle, payrollListDeleteHandle, payrollListOnChangeHandle, payrollListLoading, payrollListAlert, payrollListRefundDelete, payrollListEdit, payrollListGetData } = payrollListSlice.actions

export default payrollListSlice.reducer
