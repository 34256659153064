// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../../configs/apiConfig'


export const manageVehicleCheckCreate = createAsyncThunk('manageVehicleCheck/application/create', async (params) => {
  const response = await apiHandle('POST', 'application/paybalance', params)
  return response
})

export const manageVehicleCheckRefund = createAsyncThunk('manageVehicleCheck/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const manageVehicleCheckDelete = createAsyncThunk('manageVehicleCheck/application/delete', async (params) => {
  const response = await apiHandle('POST', 'application/delete', params)
  return response
})


export const manageVehicleCheckPDFCreate = createAsyncThunk('manageVehicleCheck/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const manageVehicleCheckReaderCard = createAsyncThunk('manageVehicleCheck/manageVehicleCheckReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const getDataTable = createAsyncThunk('manageVehicleCheck/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'application/gettable', params)
  return response
})

export const manageVehicleCheckDataDetail = createAsyncThunk('manageVehicleCheck/manageVehicleCheckDataDetail', async (params) => {
  const response = await apiHandle('GET', 'application/get', params)
  return response
})

export const manageVehicleCheckGetCourse = createAsyncThunk('manageVehicleCheck/manageVehicleCheckGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getformanageVehicleCheck', params)
  return response
})

export const getAllData = createAsyncThunk('manageVehicleCheck/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('manageVehicleCheck/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('manageVehicleCheck/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('manageVehicleCheck/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('manageVehicleCheck/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

const defaultCoruseCost = {
  course_id: '',
  course_name: null,
  promotion_code: null,
  exam_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultOtherCost = {
  other_fee_id: null,
  other_fee_name: null,
  promotion_code: null,
  exam_date: null,
  cost: null,
  discount: null,
  paid: null,
  pay_type: null
}

const defaultPayments = {
  payment_id: null,
  payment_code: null,
  pay_type: null,
  paid: null,
  createdAt: null
}


const defaultReisterDetail = {
  is_foreigner: false,
  prefix: '',
  name: '',
  idcard: '',
  birthday: null,
  address: null,
  moo: '',
  soi: '',
  street: '',
  subdistrict: '',
  district: '',
  province: '',
  phone_1: '',
  phone_2: '',
  age: '',
  course: [defaultCoruseCost],
  other_fee: [defaultOtherCost],
  payments: [defaultPayments],
  paid: '',
  discount: '',
  net: '',
  balance: '',
  total: '',
  // pay_type: 'เงินสด',
  status: null,
  application_code: null,
  course_id: '',
  createStatus: false,
  application_id: null,
  pdf: []
}


export const manageVehicleCheckSlice = createSlice({
  name: 'manageVehicleCheck',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ค้างชำระ', value: 'ค้างชำระ' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    manageVehicleCheckData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    manageVehicleCheckDetail: {
      isEdit: false,
      dropdown: {
        course: [],
        other_fee: []
      },
      userData: defaultReisterDetail
    },
    alert: {
      active: false,
      message: ''
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'manageVehicleCheck/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    manageVehicleCheckNew: (state, action) => {
      if (action.type === 'manageVehicleCheck/manageVehicleCheckNew') {
        state.manageVehicleCheckDetail.isEdit = false
        state.manageVehicleCheckDetail.userData = defaultReisterDetail
      }
    },
    manageVehicleCheckAddHandle: (state, action) => {
      if (action.type === 'manageVehicleCheck/manageVehicleCheckAddHandle') {
        if (action.payload.target === 'course') {
          state.manageVehicleCheckDetail.userData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.manageVehicleCheckDetail.userData.other_fee.push(defaultOtherCost)
        }

      }
    },
    manageVehicleCheckDeleteHandle: (state, action) => {
      if (action.type === 'manageVehicleCheck/manageVehicleCheckDeleteHandle') {
        if (action.payload.target === 'course') {
          state.manageVehicleCheckDetail.userData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.manageVehicleCheckDetail.userData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    manageVehicleCheckDropdownHandle: (state, action) => {
      if (action.type === 'manageVehicleCheck/manageVehicleCheckDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.manageVehicleCheckDetail.isEdit === false) {
          state.manageVehicleCheckDetail.userData.course[action.payload.index].course_id = action.payload.value.course_id
          state.manageVehicleCheckDetail.userData.course[action.payload.index].course_name = action.payload.value.name
          state.manageVehicleCheckDetail.userData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.manageVehicleCheckDetail.userData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.manageVehicleCheckDetail.isEdit === false) {
          state.manageVehicleCheckDetail.userData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.manageVehicleCheckDetail.userData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.manageVehicleCheckDetail.userData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.manageVehicleCheckDetail.userData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    manageVehicleCheckOnChangeHandle: (state, action) => {
      if (action.type === 'manageVehicleCheck/manageVehicleCheckOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.manageVehicleCheckDetail.userData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.manageVehicleCheckDetail.userData[action.payload.target] = action.payload.value
        }

      }
    },
    manageVehicleCheckLoading: (state, action) => {
      if (action.type === 'manageVehicleCheck/manageVehicleCheckLoading') {
        state.manageVehicleCheckData.isLoading = true
      }
    },
    manageVehicleCheckAlert: (state, action) => {
      if (action.type === 'manageVehicleCheck/manageVehicleCheckAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageVehicleCheckRefundDelete: (state, action) => {
      if (action.type === 'manageVehicleCheck/manageVehicleCheckRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(manageVehicleCheckCreate.fulfilled, (state, action) => {
        if (action.type === 'manageVehicleCheck/application/create/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            console.log(action)
            state.alert.active = true
            state.alert.message = action.payload.data.pdf
            // state.manageVehicleCheckDetail.userData.createStatus = true
            // state.manageVehicleCheckDetail.userData.pdf = action.payload.data
          }
        }
      })
      .addCase(manageVehicleCheckRefund.fulfilled, (state, action) => {
        if (action.type === 'manageVehicleCheck/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(manageVehicleCheckDelete.fulfilled, (state, action) => {
        if (action.type === 'manageVehicleCheck/application/delete/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'manageVehicleCheck/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageVehicleCheckData = action.payload
            state.manageVehicleCheckData.isLoading = false
          }
        }
      })
      .addCase(manageVehicleCheckDataDetail.fulfilled, (state, action) => {
        if (action.type === 'manageVehicleCheck/manageVehicleCheckDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            console.log(action)
            state.manageVehicleCheckDetail.isEdit = true
            state.manageVehicleCheckDetail.userData.prefix = action.payload.data.customer.prefix ? action.payload.data.customer.prefix : ''
            state.manageVehicleCheckDetail.userData.name = action.payload.data.customer.name ? action.payload.data.customer.name : ''
            state.manageVehicleCheckDetail.userData.idcard = action.payload.data.customer.idcard ? action.payload.data.customer.idcard : ''
            state.manageVehicleCheckDetail.userData.birthday = action.payload.data.customer.birthday ? action.payload.data.customer.birthday : null
            state.manageVehicleCheckDetail.userData.address = action.payload.data.customer.address ? action.payload.data.customer.address : ''
            state.manageVehicleCheckDetail.userData.moo = action.payload.data.customer.moo ? action.payload.data.customer.moo : ''
            state.manageVehicleCheckDetail.userData.soi = action.payload.data.customer.soi ? action.payload.data.customer.soi : ''
            state.manageVehicleCheckDetail.userData.street = action.payload.data.customer.street ? action.payload.data.customer.street : ''
            state.manageVehicleCheckDetail.userData.province = action.payload.data.customer.province ? action.payload.data.customer.province : ''
            state.manageVehicleCheckDetail.userData.district = action.payload.data.customer.district ? action.payload.data.customer.district : ''
            state.manageVehicleCheckDetail.userData.subdistrict = action.payload.data.customer.subdistrict ? action.payload.data.customer.subdistrict : ''
            state.manageVehicleCheckDetail.userData.phone_1 = action.payload.data.customer.phone_1 ? action.payload.data.customer.phone_1 : ''
            state.manageVehicleCheckDetail.userData.phone_2 = action.payload.data.customer.phone_2 ? action.payload.data.customer.phone_2 : ''
            state.manageVehicleCheckDetail.userData.status = action.payload.data.status ? action.payload.data.status : null
            state.manageVehicleCheckDetail.userData.application_code = action.payload.data.application_code ? action.payload.data.application_code : null
            state.manageVehicleCheckDetail.userData.paid = action.payload.data.paid === 0 ? '0' : action.payload.data.paid
            state.manageVehicleCheckDetail.userData.course_id = action.payload.data.paid ? action.payload.data.course_id.toString() : ''
            state.manageVehicleCheckDetail.userData.discount = action.payload.data.discount === 0 ? '0' : action.payload.data.discount.toString()
            state.manageVehicleCheckDetail.userData.net = action.payload.data.net === 0 ? '0' : action.payload.data.net.toString()
            state.manageVehicleCheckDetail.userData.balance = action.payload.data.balance === 0 ? '0' : action.payload.data.balance.toString()
            state.manageVehicleCheckDetail.userData.total = action.payload.data.total === 0 ? '0' : action.payload.data.total.toString()
            state.manageVehicleCheckDetail.userData.payments = action.payload.data.payments
            state.manageVehicleCheckDetail.userData.application_id = action.payload.data.application_id
            state.manageVehicleCheckDetail.userData.is_foreigner = action.payload.data.customer.is_foreigner
            state.manageVehicleCheckDetail.userData.course = [
              {
                course_id: action.payload.data.course_id ? action.payload.data.course_id.toString() : '',
                course_name: null,
                promotion_code: null,
                exam_date: null,
                cost: action.payload.data.balance ? action.payload.data.balance.toString() : '',
                discount: null,
                paid: null,
                pay_type: null
              }
            ]
          }
        }
      })
      .addCase(manageVehicleCheckGetCourse.fulfilled, (state, action) => {
        if (action.type === 'manageVehicleCheck/manageVehicleCheckGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageVehicleCheckDetail.dropdown.course = action.payload.course
            state.manageVehicleCheckDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(manageVehicleCheckReaderCard.fulfilled, (state, action) => {
        if (action.type === 'manageVehicleCheck/manageVehicleCheckReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.manageVehicleCheckDetail.userData.prefix = action.payload.title_th
            state.manageVehicleCheckDetail.userData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.manageVehicleCheckDetail.userData.idcard = action.payload.nat_id
            state.manageVehicleCheckDetail.userData.birthday = action.payload.birthdate
            state.manageVehicleCheckDetail.userData.address = action.payload.address_no
            state.manageVehicleCheckDetail.userData.street = action.payload.address_road
            state.manageVehicleCheckDetail.userData.province = action.payload.address_provinice
            state.manageVehicleCheckDetail.userData.district = action.payload.address_amphor
            state.manageVehicleCheckDetail.userData.subdistrict = action.payload.address_tumbol
            state.manageVehicleCheckDetail.userData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(manageVehicleCheckPDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'manageVehicleCheck/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, manageVehicleCheckNew, manageVehicleCheckAddHandle, manageVehicleCheckDropdownHandle, manageVehicleCheckDeleteHandle, manageVehicleCheckOnChangeHandle, manageVehicleCheckLoading, manageVehicleCheckAlert, manageVehicleCheckRefundDelete } = manageVehicleCheckSlice.actions

export default manageVehicleCheckSlice.reducer
