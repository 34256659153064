// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import apiHandle from '../../../../configs/apiConfig'


export const manageVehicleCreate = createAsyncThunk('manageVehicle/vehicle/create', async (params) => {
  const response = await apiHandle('POST', 'vehicle/create', params)
  return response
})

export const manageVehicleUpdate = createAsyncThunk('manageVehicle/vehicle/update', async (params) => {
  const response = await apiHandle('PUT', 'vehicle/update', params)
  return response
})

export const manageVehicleRefund = createAsyncThunk('manageVehicle/application/refund', async (params) => {
  const response = await apiHandle('POST', 'application/refund', params)
  return response
})

export const manageVehicleDelete = createAsyncThunk('manageVehicle/vehicle/delete', async (params) => {
  const response = await apiHandle('DELETE', 'vehicle/delete', params)
  return response
})


export const manageVehiclePDFCreate = createAsyncThunk('manageVehicle/application/pdf/create', async (params) => {
  const response = await apiHandle('POST', 'application/pdf', params)
  return response
})

export const manageVehicleReaderCard = createAsyncThunk('manageVehicle/manageVehicleReaderCard', async () => {
  const response = await apiHandle('GET', 'mockreadercard')
  return response
})

export const manageVehicleGetList = createAsyncThunk('manageVehicle/vehicle/list', async (params) => {
  const response = await apiHandle('GET', 'vehicle/list', params)
  return response
})


export const getDataTable = createAsyncThunk('manageVehicle/getDataTable', async (params) => {
  const response = await apiHandle('GET', 'vehicle/gettable', params)
  return response
})


export const manageVehicleDataDetail = createAsyncThunk('manageVehicle/manageVehicleDataDetail', async (params) => {
  const response = await apiHandle('GET', 'vehicle/get', params)
  return response
})

export const manageVehicleGetCourse = createAsyncThunk('manageVehicle/manageVehicleGetCourse', async (params) => {
  const response = await apiHandle('GET', 'application/getformanageVehicle', params)
  return response
})

export const getAllData = createAsyncThunk('manageVehicle/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('manageVehicle/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('manageVehicle/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('manageVehicle/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('manageVehicle/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})


const defaultVehicleDetail = {
  branch: null,
  branch_id: null,
  brand: null,
  color: null,
  createdAt: null,
  insurance_company: null,
  insurance_type: null,
  is_delete: null,
  model: null,
  no: null,
  note: null,
  noti_check: null,
  noti_compulsory: null,
  noti_insurance: null,
  noti_tax: null,
  province: null,
  purchase_date: null,
  status: null,
  type: null,
  updatedAt: null,
  vehicle_id: null,
  createStatus: false
}


export const manageVehicleSlice = createSlice({
  name: 'manageVehicle',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    searchCriteria: {
      branch: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      status: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      type: { label: 'ทั้งหมด', value: 'ทั้งหมด' },
      text: ''
    },
    manageVehicleData: {
      data: [],
      filter: {
        branch: [],
        status: [],
        type: []
      },
      meta: {},
      isLoading: false
    },
    manageVehicleDetail: {
      isEdit: false,
      dropdown: {
        branch: [],
        status: [],
        type: []
      },
      vehicleData: defaultVehicleDetail
    },
    alert: {
      active: false,
      message: ''
    },
    refundAndDelete: {
      application_id: null,
      note: null,
      value: null,
      err: false
    }
  },
  reducers: {
    searchCriteria: (state, action) => {
      if (action.type === 'manageVehicle/searchCriteria') {
        state.searchCriteria[action.payload.target] = action.payload.value
      }
    },
    manageVehicleNew: (state, action) => {
      if (action.type === 'manageVehicle/manageVehicleNew') {
        state.manageVehicleDetail.isEdit = false
        state.manageVehicleDetail.vehicleData = defaultVehicleDetail
      }
    },
    manageVehicleAddHandle: (state, action) => {
      if (action.type === 'manageVehicle/manageVehicleAddHandle') {
        if (action.payload.target === 'course') {
          state.manageVehicleDetail.vehicleData.course.push(defaultCoruseCost)
        }
        if (action.payload.target === 'other') {
          state.manageVehicleDetail.vehicleData.other_fee.push(defaultOtherCost)
        }

      }
    },
    manageVehicleDeleteHandle: (state, action) => {
      if (action.type === 'manageVehicle/manageVehicleDeleteHandle') {
        if (action.payload.target === 'course') {
          state.manageVehicleDetail.vehicleData.course.splice(action.payload.index, 1)
        }
        if (action.payload.target === 'other') {
          state.manageVehicleDetail.vehicleData.other_fee.splice(action.payload.index, 1)
        }

      }

    },
    manageVehicleDropdownHandle: (state, action) => {
      if (action.type === 'manageVehicle/manageVehicleDropdownHandle') {
        console.log(action.payload.target)
        if (action.payload.target === 'course' && state.manageVehicleDetail.isEdit === false) {
          state.manageVehicleDetail.vehicleData.course[action.payload.index].course_id = action.payload.value.course_id
          state.manageVehicleDetail.vehicleData.course[action.payload.index].course_name = action.payload.value.name
          state.manageVehicleDetail.vehicleData.course[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'examDate') {
          state.manageVehicleDetail.vehicleData.course[action.payload.index].exam_date = action.payload.value
        }
        if (action.payload.target === 'other' && state.manageVehicleDetail.isEdit === false) {
          state.manageVehicleDetail.vehicleData.other_fee[action.payload.index].other_fee_id = action.payload.value.other_fee_id
          state.manageVehicleDetail.vehicleData.other_fee[action.payload.index].other_fee_name = action.payload.value.name
          state.manageVehicleDetail.vehicleData.other_fee[action.payload.index].cost = action.payload.value.price
        }
        if (action.payload.target === 'pay_type') {
          state.manageVehicleDetail.vehicleData[action.payload.mode][action.payload.index].pay_type = action.payload.value
        }
      }
    },
    manageVehicleOnChangeHandle: (state, action) => {
      if (action.type === 'manageVehicle/manageVehicleOnChangeHandle') {
        if (action.payload.target === 'paid') {
          state.manageVehicleDetail.vehicleData[action.payload.mode][action.payload.index][action.payload.target] = action.payload.value
        } else {
          state.manageVehicleDetail.vehicleData[action.payload.target] = action.payload.value
        }

      }
    },
    manageVehicleLoading: (state, action) => {
      if (action.type === 'manageVehicle/manageVehicleLoading') {
        state.manageVehicleData.isLoading = true
      }
    },
    manageVehicleAlert: (state, action) => {
      if (action.type === 'manageVehicle/manageVehicleAlert') {
        state.alert.active = false
        state.alert.message = ''
        state.alert.err = false
      }
    },
    manageVehicleRefundDelete: (state, action) => {
      if (action.type === 'manageVehicle/manageVehicleRefundDelete') {
        if (action.payload.target === null) {
          state.refundAndDelete.application_id = null
          state.refundAndDelete.note = null
          state.refundAndDelete.value = null
        } else {
          state.refundAndDelete[action.payload.target] = action.payload.value
        }

      }
    }

  },
  extraReducers: builder => {
    builder
      .addCase(manageVehicleCreate.fulfilled, (state, action) => {
        if (action.type === 'manageVehicle/vehicle/create/fulfilled') {
          if (action.payload.status === 'error') {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            //handleError
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(manageVehicleUpdate.fulfilled, (state, action) => {
        if (action.type === 'manageVehicle/vehicle/update/fulfilled') {
          if (action.payload.status === 'error') {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            //handleError
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(manageVehicleRefund.fulfilled, (state, action) => {
        if (action.type === 'manageVehicle/application/refund/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.err = true
            state.alert.message = action.payload.message
          } else {
            state.refundAndDelete.application_id = null
            state.refundAndDelete.note = null
            state.refundAndDelete.value = null
            state.alert.active = true
            state.alert.message = action.payload.message
          }
        }
      })
      .addCase(manageVehicleDelete.fulfilled, (state, action) => {
        if (action.type === 'manageVehicle/vehicle/delete/fulfilled') {
          if (action.payload.status === 'error') {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = true
            //handleError
          } else {
            state.alert.active = true
            state.alert.message = action.payload.message
            state.alert.err = false
          }
        }
      })
      .addCase(manageVehicleGetList.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'manageVehicle/vehicle/list/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            if (action.payload.data.branch) {
              let branch = []
              for (const i of action.payload.data.branch) {
                branch.push({ value: i.value, label: i.name })
              }
              state.manageVehicleDetail.dropdown.branch = branch
            } if (action.payload.data.status) {
              let status = []
              for (const i of action.payload.data.status) {
                status.push({ value: i, label: i })
              }
              state.manageVehicleDetail.dropdown.status = status
            } if (action.payload.data.type) {
              let type = []
              for (const i of action.payload.data.type) {
                type.push({ value: i, label: i })
              }
              state.manageVehicleDetail.dropdown.type = type
            }
          }
        }
      })
      .addCase(getDataTable.fulfilled, (state, action) => {
        if (action.type === 'manageVehicle/getDataTable/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            if (action.payload.filter && Array.isArray(action.payload.filter.status)) {
              let arr = [{ name: 'ทั้งหมด', value: 'ทั้งหมด' }]
              for (let i of action.payload.filter.status) {
                arr.push({ name: i, value: i })
              }
              action.payload.filter.status = arr
            }
            if (action.payload.filter && Array.isArray(action.payload.filter.type)) {
              let arr = [{ name: 'ทั้งหมด', value: 'ทั้งหมด' }]
              for (let i of action.payload.filter.type) {
                arr.push({ name: i, value: i })
              }
              action.payload.filter.type = arr
            }
            console.log(action.payload)
            state.manageVehicleData = action.payload
            state.manageVehicleData.isLoading = false
          }
        }
      })
      .addCase(manageVehicleDataDetail.fulfilled, (state, action) => {
        if (action.type === 'manageVehicle/manageVehicleDataDetail/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            console.log(action)
            state.manageVehicleDetail.isEdit = true
            for (const i in action.payload.data) {
              let data = action.payload.data[i]
              if (data === null) data = null
              state.manageVehicleDetail.vehicleData[i] = data
            }
          }
        }
      })
      .addCase(manageVehicleGetCourse.fulfilled, (state, action) => {
        if (action.type === 'manageVehicle/manageVehicleGetCourse/fulfilled') {
          if (action.payload.status === 'error') {
            //handleError
          } else {
            state.manageVehicleDetail.dropdown.course = action.payload.course
            state.manageVehicleDetail.dropdown.other_fee = action.payload.other_fee
          }
        }
      })
      .addCase(manageVehicleReaderCard.fulfilled, (state, action) => {
        if (action.type === 'manageVehicle/manageVehicleReaderCard/fulfilled') {
          if (action.payload.result === 'ok') {
            state.manageVehicleDetail.vehicleData.prefix = action.payload.title_th
            state.manageVehicleDetail.vehicleData.name = `${action.payload.fname_th} ${action.payload.sname_th}`
            state.manageVehicleDetail.vehicleData.idcard = action.payload.nat_id
            state.manageVehicleDetail.vehicleData.birthday = action.payload.birthdate
            state.manageVehicleDetail.vehicleData.address = action.payload.address_no
            state.manageVehicleDetail.vehicleData.street = action.payload.address_road
            state.manageVehicleDetail.vehicleData.province = action.payload.address_provinice
            state.manageVehicleDetail.vehicleData.district = action.payload.address_amphor
            state.manageVehicleDetail.vehicleData.subdistrict = action.payload.address_tumbol
            state.manageVehicleDetail.vehicleData.age = null

          } else {
            //handleError
          }
        }
      })
      .addCase(manageVehiclePDFCreate.fulfilled, (state, action) => {
        console.log(action)
        if (action.type === 'manageVehicle/application/pdf/create/fulfilled') {
          if (action.payload.status === 'success') {
            window.open(action.payload.data.pdf, '_blank')
          } else {
            //handleError
          }
        }
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })

  }
})

export const { searchCriteria, manageVehicleNew, manageVehicleAddHandle, manageVehicleDropdownHandle, manageVehicleDeleteHandle, manageVehicleOnChangeHandle, manageVehicleLoading, manageVehicleAlert, manageVehicleRefundDelete } = manageVehicleSlice.actions

export default manageVehicleSlice.reducer
